import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

const api: string = environment.baseUrl;

@Injectable({
    providedIn: 'root'
})
export class ManagementService {
    constructor(private http: HttpClient) { }

    getRolesAndRightsService(): Observable<any[]> {
        let restaurantId = Number(sessionStorage.getItem('resturantId'));
        return this.http.get<any[]>(api + '/api/Management/FindRolesAndRights?RestaurantId=' + restaurantId);
    }
    updateRoles(userId: string, roles: string): Observable<any> {
        let restaurantId =Number(sessionStorage.getItem('resturantId'));
        return this.http.put(api + '/api/Management/UpdateRoles?RestaurantId=' + restaurantId + '&UserId=' + userId + '&Roles=' + roles , {});
    }
    ResetUserRights(userId: string, roles: string): Observable<any> {
        let restaurantId =Number(sessionStorage.getItem('resturantId'));
        return this.http.put(api + '/api/Management/ResetUserRights?RestaurantId=' + restaurantId + '&userId=' + userId + '&roleName=' + roles , {});
    }
    updateUserRights(user: any) {
        let restaurantId =Number(sessionStorage.getItem('resturantId'));
        return this.http.put(api + '/api/Management/UpdateUserRights?RestaurantId=' + restaurantId, user);
    }
    updateRoleRights(user: any) {
        let restaurantId =Number(sessionStorage.getItem('resturantId'));

        if(restaurantId==0){
            return this.http.put(api + '/api/Management/UpdateProviderRoleRights', user);
        }else{
            return this.http.put(api + '/api/Management/UpdateRestaurantRoleRights?RestaurantId=' + restaurantId, user);
        }
    }
    ResetResturantRight() {
        let restaurantId =Number(sessionStorage.getItem('resturantId'));
        if(restaurantId==0){
            return this.http.put(api + '/api/Management/ResetProviderRights', {});
        }else{
            return this.http.put(api + '/api/Management/ResetRestaurantRights?RestaurantId=' + restaurantId, {});
        }
    }
    getAllRightsService(): Observable<any[]> {
        let restaurantId = Number(sessionStorage.getItem('resturantId'));
        return this.http.get<any[]>(api + '/api/Management/FindAllRights?RestaurantId=' + restaurantId);
    }
}