import { Component, OnInit } from '@angular/core';
import { BankAccountService } from 'src/app/shared/services/api/bank-account.service';
import { ModalOptions } from '@ionic/core';
import { ModalController } from '@ionic/angular';
import { CreateCreditCardComponent } from '../create-credit-card/create-credit-card.component';
import { ProfileCards } from 'src/app/shared/models/bank.model';
@Component({
  selector: 'app-card-info',
  templateUrl: './card-info.component.html',
  styleUrls: ['./card-info.component.scss'],
})
export class CardInfoComponent implements OnInit {
  profileCards: ProfileCards[];
  cardActive: any;
  constructor(private bankAccountService: BankAccountService,
    public modalCtrl: ModalController) { }

  ngOnInit() {
    this.getAllCards();
  }
  getAllCards() {
    this.profileCards = [];
    this.bankAccountService.getAllCards().subscribe((data) => {
      console.log(data)
      this.profileCards = data;
    })
  }

  removeCard(id) {
    this.bankAccountService.removeCard(id).subscribe(data => {
      console.log(data)
      this.getAllCards();
    })
  }
  async openCardDetails() {
    const cardDetailModal = await this.modalCtrl.create({
      component: CreateCreditCardComponent,
    });
    await cardDetailModal.present();
    const { data } = await cardDetailModal.onWillDismiss();
    if (data === true) {
      this.bankAccountService.getAllCards().subscribe((data) => {
        console.log(data)
        this.profileCards = data;
        if (this.profileCards.length === 1) {
          if (this.profileCards[0].isActive === true) {
            this.bankAccountService.isActiveCardAvailable().subscribe(data => {
              console.log(data)
              this.cardActive = data;
              if (this.cardActive === true) {
                this.bankAccountService.isCreditCardSubmit(true);
              }
            })
          }
        }
      })
    }
  }
  toggleChange(e,id: any){
    console.log(e);
    this.profileCards.forEach(ele => {
      if (ele.id === id) {
        ele.isActive  = e;
        this.bankAccountService.updateActiveCard(id,  ele.isActive).subscribe(data=>{
          console.log(data);
        //  this.cdref.detectChanges();
          if (data) {             
           this.getAllCards();
           
          }
        });
      }
    })
    
  }
}
