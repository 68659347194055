import { Component, Input, OnInit, PipeTransform } from '@angular/core';
import { ActionSheetController, ModalController } from '@ionic/angular';
import { OrderService } from 'src/app/shared/services/api/orders.service';
import { CustomerCRMUIComponent } from '../../customer-crm-ui/customer-crm-ui.component';
import { ReportService } from 'src/app/shared/services/api/report.service';
import { MessageService } from 'src/app/shared/services/message-service.service';
import { RestaurantService } from 'src/app/shared/services/api/restaurant.service';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-notifications-items-list',
  templateUrl: './notifications-items-list.component.html',
  styleUrls: ['./notifications-items-list.component.scss'],
})
export class NotificationsItemsListComponent implements OnInit,PipeTransform {
  @Input() details: any;
  screenType: any;
  data: any;
  itemorderdetails:any
  constructor(
    private readonly modalCtrl: ModalController,
    private orderService: OrderService,
    private actionSheetCtrl: ActionSheetController,
    private reportService: ReportService,
    private restaurantService: RestaurantService,
    private messageService: MessageService
    ) {}

  ngOnInit() {
    console.log(this.details);
    this.screenType = this.details?.screentype;
    if (this.screenType == 'orderdetails') {
      this.getorderdetails()
    } else if(this.screenType == 'IngredientsList')  {
      this.data=this.details.data.offBuckets
      this.FindNotificationDetails()
    }
    else if(this.screenType == 'inorederiteamList')  {
      this.getAllorderitemdetails()
    }else if(this.screenType == 'itemreportitemlist'){
      this.generateItemReport()

    }
  }

  transform(value: any, ...args: any[]) {
    if (typeof (value) == 'number') {
      let ammount = value.toLocaleString("en-US", { style: "currency", currency: "USD", });
      let ammount1 = ammount.replace(/,/g, ' ')
      let ammount2 = ammount1.replace('$', ' ')
      let ammount3 = ammount2.replace('.00', ' ')
      let ammount4 = ammount3 + ' ' 
      return ammount4.toString()
    } else {
      return value
    }
 
  }



  generateItemReport() {
    const obj = {
      startDate:this.details.data.finaldate,
      endDate: this.details.data.finaldate,
      xDisplayType:
        this.details.data.xDisplayType === 'month'
          ? 2
          : this.details.data.xDisplayType === 'year'
          ? 3
          : 1,
      yDisplayType: 2,
      restaurantIds: this.details.data.RestaurantIds,
      EatIn: this.details.data.EatIn,
      TakeAway: this.details.data.TakeAway,
      Delivered: this.details.data.Delivered,
      DeliveryProvidersId:this.details.data.DeliveryProvidersId,
      ChartType:'LineChart'
    };
    this.reportService.generateItemReport(obj).subscribe((res) => {
      this.itemorderdetails=res
    console.log(res)

    })
  }



  exportorders(){
    this.restaurantService.OrderItemDetailReport(this.details.data.orderId,this.details.data.itemId,this.details.data.isItem).subscribe((result)=>{
      if(result){
        console.log(result)
        saveAs(result, `Ordersreport.xlsx`);
        this.messageService.presentToast('Export Repot Done')
      }
    })
  
  }

  temformat(value) {
    const fractionalPart = Number(value) % 1;
    if (fractionalPart >= 0.5) {
      return Math.ceil(Number(value));
    } else if (value == 'NaN') {
      return 0;
    } else {
      return Math.floor(Number(value));
    }
  }





  getProgressbarcolor(status){
    if(status === 'Payment Done'||status === 'Partially Paid'||status === 'Delivered'){
       return 'success'
    }else if(status === 'Available'){
      return 'secondary'

    }else if(status === 'On going'){
      return 'warning'

    }else if(status === 'Ordered'){
      return 'danger'

    }
  }

  getitemdetails(value){
    this.modalCtrl.dismiss(value)
  }


  getAllorderitemdetails(){
    this.orderService.FindOrderItemDetails(this.data.orderId,this.data.itemId,this.data.isItem).subscribe((result)=>{
      if(result){
        if(result.menuItemDtos.length!=0){
          this.data=result.menuItemDtos
          console.log(result)
        }else{
          this.data=result.orderItemDtos
          console.log(result)
        }

      }
    })
  }

  getorderdetails(){
    this.orderService.FindOrderDetails(this.data.id).subscribe((result)=>{
      if(result){
        this.data=result.orderItemDtos
        console.log(result)
      }
    })
  }

 FindNotificationDetails(){
    this.orderService.FindNotificationDetails(this.details.data.id,this.details.data.action).subscribe((result)=>{
      if(result){
        this.data=result.offBuckets
        console.log(result)
      }
    })
  }

  popupclose() {
    this.modalCtrl.dismiss();
  }


  Progressvalue(value){
    if(value.status=='On going'){
      return 80
    }
  }



  async iteamOptions(data){
    const actionSheet = await this.actionSheetCtrl.create({
      buttons: [
        {
          text: 'Customer CRM',
          icon: 'person-outline',
          cssClass: 'myActionSheetBtnStyle ',
          handler: () => {
            this.ShowUserCRMDetails(data)
          }
        },
        {
          text: 'Receipt',
          icon: 'newspaper-outline',
          cssClass: 'myActionSheetBtnStyle ',
          handler: () => {
          }
        },
      ]
    });
    await actionSheet.present();
  }


  async ShowUserCRMDetails(data) {
    const modal = await this.modalCtrl.create({
      component: CustomerCRMUIComponent,
      componentProps: { items: [data] },
    });
    return await modal.present();

}
}
