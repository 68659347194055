import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { MessagesService } from 'src/app/pages/auth/Guided_Visite/messages.service';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
})
export class FilterComponent implements OnInit {
  form: FormGroup;
  IsStock:boolean=true
  IsOrder:boolean=true
  constructor(
    private readonly modalCtrl: ModalController,
    private formBuilder: FormBuilder, 
    public guidedvisitemessage:MessagesService
  ) { 
    this.form = this.formBuilder.group({
      startDate: [''],
      endDate: [''],
      IsOrder:[''],
      IsStock:[''],
    });
  }

  ngOnInit() {}
closepopup(){
  this.modalCtrl.dismiss()
}


  IsStockstatus(event){
    this.IsStock = event.detail.checked

  }
  IsOrderstatus(event){
    this.IsOrder = event.detail.checked

  }
  onSubmit(){
    if(localStorage.getItem('GuidedVisite')=='true'){
      this.guidedvisitemessage.GuidedVisitemessage('Filter','This button allows you to Filter data')
      }else{
    this.form.get('IsOrder').setValue(this.IsOrder)
    this.form.get('IsStock').setValue(this.IsStock)
    this.modalCtrl.dismiss(this.form.value)
      }
  }

}
