import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { User } from 'src/app/shared/models/user.model';
import { UserEditComponent } from '../resource-management/user-edit/user-edit.component';
import { ProfilePickFormatService } from 'src/app/shared/services/profile-pick-format.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  form: FormGroup;
  @Input() user: User;

  constructor(
    public modalController: ModalController,
    private formBuilder: FormBuilder,
    public modalCtrl: ModalController,
        private profilepickformat: ProfilePickFormatService,
  ) { }

  ngOnInit() {

    this.form = this.formBuilder.group({
      userName: [this.user.userName],
      firstName: [this.user.firstName],
      lastName: [this.user.lastName],
      email: [this.user.email],
      Gender: [this.user.genderValue==0?'Male':'Female'],
      restaurantName: [this.user.restaurantName],
      address: [this.user.address],
      city: [this.user.city],
      zipCode: [this.user.zipCode],
      country: [this.user.country],
      roleName: [this.user.roleName],
      phoneNumber: [this.user.phoneNumber]
    });
  }

  getuserFLnames(value1,value2) {
    return this.profilepickformat.getuserFLnames(value1,value2);
  }
 
  getColor(index) {
    return this.profilepickformat.getColor(index);
  } 
    async openEditUser() {
      const modal = await this.modalCtrl.create({
        component: UserEditComponent,
        componentProps: {
          user: this.user
        }
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      if (data) {
        window.location.reload()
      }
    }

}
