import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Table } from '../../models/table.model';

const api: string = environment.baseUrl;

@Injectable({
    providedIn: 'root'
})
export class TableService {
    constructor(private http: HttpClient) { }
    private subject = new Subject<any>();
    sendMessage(message: any) {
        this.subject.next(message);
    }

    clearMessages() {
        this.subject.next();
    }

    getMessage(): Observable<any> {
        return this.subject.asObservable();
    }

    FindEventById(eventId): Observable<any> {
        return this.http.get(api + '/api/Event/FindEventById?EventId=' + eventId);
    }

    getAllTableService(): Observable<any> {
        let restaurantId = Number(sessionStorage.getItem('resturantId'));
        return this.http.get<any>(api + '/api/TableManagements/FindRestauantTables?RestaurantId=' + restaurantId);
    }
    FindReservedTables(PageNumber, NumberOfRecord,): Observable<any> {
        let restaurantId = Number(sessionStorage.getItem('resturantId'));
        return this.http.get<any>(api + '/api/TableManagements/FindReservedTables?RestaurantId=' + restaurantId+'&NumberOfRecord='+NumberOfRecord+'&PageNumber='+PageNumber);
    }
    getAllTableServicewithpagination(PageNumber,NumberOfRecord,Search,IsFreeTable): Observable<any> {
        let restaurantId = Number(sessionStorage.getItem('resturantId'));
        return this.http.get<any>(api + '/api/TableManagements/FindRestauantTables?RestaurantId=' + restaurantId+'&PageNumber='+PageNumber+'&NumberOfRecord='+NumberOfRecord+'&Search='+Search+'&IsFreeTable='+IsFreeTable);
    }



    getallWaiters(): Observable<Table[]> {
        let restaurantId = Number(sessionStorage.getItem('resturantId'));
        return this.http.get<Table[]>(api + '/api/User/FindAllWaiters?RestaurantId=' + restaurantId);
    }

    // getAssignedTablesService(): Observable<Table[]> {
    //     return this.http.get<Table[]>(api + '/api/TableManagements/FindAssignedTables');
    // }

    getReservedTablesService(): Observable<Table[]> {
        let restaurantId = Number(sessionStorage.getItem('resturantId'));
        return this.http.get<Table[]>(api + '/api/TableManagements/FindReservedTables?RestaurantId=' + restaurantId);
    }
    getReservedTablesServiceWithpagination(PageNumber,NumberOfRecord,Search): Observable<Table[]> {
        let restaurantId = Number(sessionStorage.getItem('resturantId'));
        return this.http.get<Table[]>(api + '/api/TableManagements/FindReservedTables?RestaurantId=' + restaurantId+'&PageNumber='+PageNumber+'&NumberOfRecord='+NumberOfRecord+'&Search='+Search);
    }

    updateReservation(request: any): Observable<any> {
        let restaurantId = Number(sessionStorage.getItem('resturantId'));
        return this.http.put<any>(api + '/api/TableManagements/UpdateReservation?RestaurantId=' + restaurantId, request);
    }

    deleteReservation(tableId): Observable<any> {
        return this.http.delete(api + '/api/TableManagements/DeleteReservation?Id=' + tableId);
    }
    DeletePermanantly(tableId): Observable<any> {
        return this.http.delete(api + '/api/TableManagements/DeletePermanantly?Id=' + tableId);
    }

    addTableService(request: any): Observable<any> {
        let restaurantId = Number(sessionStorage.getItem('resturantId'));
        return this.http.post<any>(api + '/api/TableManagements/Create?RestaurantId=' + restaurantId, request);
    }
    UpdateTableService(request: any): Observable<any> {
        let restaurantId = Number(sessionStorage.getItem('resturantId'));
        return this.http.put<any>(api + '/api/TableManagements/Update?RestaurantId=' + restaurantId, request);
    }

    editTableService(request: any): Observable<any> {
        let restaurantId = Number(sessionStorage.getItem('resturantId'));
        return this.http.put<any>(api + '/api/TableManagements/Update?RestaurantId=' + restaurantId, request);
    }

    assignTableToWaiterService(request: any): Observable<any> {
        return this.http.post<any>(api + ' /api/TableManagements/AssignTablesToWaiter', request);
    }

    assignTableService(request: any): Observable<any> {
        let restaurantId = Number(sessionStorage.getItem('resturantId'));
        return this.http.post<any>(api + '/api/Event/Create?RestaurantId=' + restaurantId, request);
    }

    UpdateTableStatus(request: any): Observable<any> {
        return this.http.put<any>(api + '/api/TableManagements/UpdateTableStatus', request);
    }

    closeTable(id, tableId: any): Observable<any> {
        return this.http.put<any>(api + '/api/Event/Close?EventId=' + id, tableId);
    }

    CreateReservation(request): Observable<any> {
        let restaurantId = Number(sessionStorage.getItem('resturantId'));
        return this.http.post<any>(api + '/api/TableManagements/CreateReservation?RestaurantId=' + restaurantId, request);
    }

    findTableReservation(id): Observable<any> {
        return this.http.get(api + '/api/TableManagements/FindTableReservations?TableId=' + id);
    }

    GetQRCodeURL(qrCodeMediaItemId): Observable<any> {
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', }) }
        return this.http.get(api + '/api/TableManagements/GetQRCode?MediaItemId=' + qrCodeMediaItemId);
    }

    ExpireIngredientNotification(): Observable<any> {
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', }) }
        return this.http.get(api + '/api/StockHandle/ExpireIngredientNotification');
    }

    FindFloors(): Observable<any> {
        let restaurantId = Number(sessionStorage.getItem('resturantId'));
        return this.http.get<any>(api + '/api/TableManagements/FindFloor?RestaurantId=' + restaurantId);
    }

    FindRestauantTableById(TableId): Observable<any> {
        return this.http.get<any>(api + '/api/TableManagements/FindRestauantTableById?TableId=' + TableId);
    }
    GetSavedFloorPlan(FloorId): Observable<any> {
        return this.http.get<any>(api + '/api/Floor/GetSavedFloorPlan?FloorId=' + FloorId);
    }

    GettableWaitingLists(): Observable<any> {
        let restaurantId = Number(sessionStorage.getItem('resturantId'));
        return this.http.get<any>(api + '/api/TableManagements/FindWaitingLists?RestaurantId=' + restaurantId);
    }
      // Method to fetch JSON data
  getJsonData(url: string): Observable<any> {
    return this.http.get<any>(url); // Returns an Observable containing JSON data
  }
    CreatetableWaitingList(model): Observable<any> {
        let restaurantId = Number(sessionStorage.getItem('resturantId'));
        return this.http.post<any>(api + '/api/TableManagements/CreateWaitingList?RestaurantId=' + restaurantId,model);
    }
    AdvanceFilterForWaitingLists(TicketId,CustomerName,NumberOfUsers,NumberOfRecord,PageNumber): Observable<any> {
        return this.http.get<any>(api + '/api/TableManagements/AdvanceFilterForWaitingLists?TicketId=' + TicketId+'&CustomerName='+CustomerName+'&NumberOfUsers='+NumberOfUsers+'&NumberOfRecord='+NumberOfRecord+'&PageNumber='+PageNumber);
    }
    AdvanceFilterForOnlineReservation(FloorName,CustomerName,NumberOfUsers,DeliveryProviderName,NumberOfRecord,PageNumber): Observable<any> {
        return this.http.get<any>(api + '/api/TableManagements/AdvanceFilterForOnlineReservation?FloorName=' + FloorName+'&CustomerName='+CustomerName+'&DeliveryProviderName='+DeliveryProviderName+'&NumberOfUsers='+NumberOfUsers+'&NumberOfRecord='+NumberOfRecord+'&PageNumber='+PageNumber);
    }
    SaveFloorPlan(floorId,file): Observable<any> {
        let restaurantId = Number(sessionStorage.getItem('resturantId'));
        return this.http.put<any>(api + '/api/Floor/SaveFloorPlan?floorId='+floorId,file);
    }
    UpdatetableWaitingList(model): Observable<any> {
        let restaurantId = Number(sessionStorage.getItem('resturantId'));
        return this.http.put<any>(api + '/api/TableManagements/UpdateWaitingList?RestaurantId=' + restaurantId,model);
    }

    RemovetableWaitingList(id): Observable<any> {
        let restaurantId = Number(sessionStorage.getItem('resturantId'));
        return this.http.delete<any>(api + '/api/TableManagements/RemoveWaitingList?Id=' + id);
    }

}