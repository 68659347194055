import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

const api: string = environment.baseUrl;

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  isAuthenticated$ = new BehaviorSubject<boolean>(false);
  private loadData = new Subject<any>();
  data = this.loadData.asObservable();
  constructor(private http: HttpClient) { }

  authenticate(request): Observable<any> {
    return this.http.post(api + "/api/Auth/login", request).pipe(map(data => {
        this.isAuthenticated$.next(true);
        localStorage.setItem("token", data['accessToken']);
        return data;
      })
      );
  }
  loadingData(isProvider: boolean) {
  
    this.loadData.next(isProvider);
  }
  isAuthenticated(): boolean {
    return this.isAuthenticated$.value;
  }

  forgotPassword(request): Observable<any> {
    return this.http.post(api + '/api/Auth/ForgotPassword', request)
  }
register(request): Observable<any> {
    return this.http.post(api + '/api/Accounts/register', request)
  }
  invalidate(): Observable<any> {
    return this.http.post(api + '/api/Auth/logout', {}, { responseType: 'text' })
      .pipe(
        map(data => {
          this.isAuthenticated$.next(false);
          return data;
        })
      )
  }
  getUserRoleRights(): Observable<any> {
    return this.http.get<any>(api + '/api/User/FindUserRoleRights?UserId=' + localStorage.getItem('userId'));
}

getBrokerOrganization(): Observable<any> {
  return this.http.get<any>(api + '/api/Accounts/FindAllBrokerOrganisations');
}
GetProvidersOrganisations(): Observable<any> {
  return this.http.get<any>(api + '/api/Auth/GetProvidersOrganisations');
}



GetAuthUsers(roleName:any,restaurantId:any,isProvider:any,isBroker:any,Organization:any,providerId:any): Observable<any> {
return this.http.get<any>(api + '/api/Auth/GetUsers?roleName=' + roleName+'&restaurantId='+restaurantId+'&isProvider='+isProvider+'&isBroker='+isBroker+'&brokerOrgId='+Organization+'&providerId='+providerId);
}
}
