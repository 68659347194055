import { Component, OnInit, Input } from '@angular/core';
import { AlertController, ModalController, PopoverController } from '@ionic/angular';
import { CartService } from '../../../shared/services/cart-service.service';
import { BehaviorSubject } from 'rxjs'
import { AppService } from 'src/app/app.service';
import { ProviderProductService } from 'src/app/shared/services/api/provider-product.service';
import { MessageService } from 'src/app/shared/services/message-service.service';
import { BankAccountService } from 'src/app/shared/services/api/bank-account.service';
import { ModalOptions } from '@ionic/core';
import { CardInfoComponent } from '../card-info/card-info.component';
import { NgStyle } from '@angular/common';
import { FilterItemStatusComponent } from '../filter-item-status/filter-item-status.component';
import { MessagesService } from '../../auth/Guided_Visite/messages.service';
@Component({
  selector: 'app-provider-cart',
  templateUrl: './provider-cart.page.html',
  styleUrls: ['./provider-cart.page.scss'],
})
export class ProviderCartPage implements OnInit {
  cart: any = [];
  cartItemCount: BehaviorSubject<number>;
  total: number = 0;
  user: any
  cardActive: any;
  cartItem: any = [];
  isDisable: boolean = true;
  page_number = 1;
  page_limit = 0;
  cartProducts: any;
  rights: any;
  view = false;
  add = false;
  carkCheckeditems=[]
  constructor(
    public modalCtrl: ModalController,
    private appService: AppService,
    private cartService: CartService,
    private providerProductService: ProviderProductService,
    private messageService: MessageService,
    private alertCtrl: AlertController,
    private bankAccountService: BankAccountService,
    public guidedvisitemessage:MessagesService
  ) {
    this.bankAccountService.productPay$.subscribe(
      isCreditCard => {
          if (isCreditCard === true ) {
            let obj = {
              "restaurantId": localStorage.getItem('resturantId'),
              "restaurantName": localStorage.getItem('resturantName'),
              "restaurantProfile": localStorage.getItem('resturantProfile'),
              "providers": this.cartItem
            }
            this.bankAccountService.cartPay(obj).subscribe((res) => {
              this.messageService.presentToast(res.message);
              this.getCardList(false,'',this.page_number);
            })
          } 
      }
    );
   }

  ngOnInit() {
    this.rights = JSON.parse(localStorage.getItem('permission')) || 0;
    if (this.rights && this.rights.length > 0) {
      const role = this.rights.filter((ele: any) => ele.rights === 'Provider');
      if (role.length > 0) {
        if (role[0].access.length > 0) {
          if (role[0].access.length > 1) {
            const isAll = role[0].access.filter((el: any) => el === 'View' || el === 'Update' || el === 'Create' || el === 'Delete');
            if (isAll.length > 0) {
              this.add = true;
              this.view = true;
              this.isCardActive();
            }
          } else {
            const isview = role[0].access.filter((el: any) => el === 'View');
            if (isview.length > 0) {
              this.add = false;
              this.view = true;
            }
          }
        }
      }
      console.log(this.view, this.add);
    }
  
    this.getCardList(false,'',this.page_number );
  }

  getCardList(isFirstLoad, event,page_number ) {
    this.cartService.getItemList(page_number, this.page_limit).subscribe(data => {
      this.cartProducts = data.data;
      this.cart=[]
      for (let i = 0; i < this.cartProducts.length; i++) {
        this.cart.push(this.cartProducts[i]);
      }
      if (isFirstLoad)
        event.target.complete();

      this.page_number++;
    }, error => {
      console.log(error);
    })
  }
  doInfinite(event) {
    this.getCardList(true, event,this.page_number );
  }
  
  removeFromCart(cartId: number) {
    this.providerProductService.removeProductFromCart(cartId).subscribe((res) => {
      console.log(res);
      this.messageService.presentToast(res.message);
      this.providerProductService.loadingData('cart');
      this.getCardList(false,'',this.page_number );
    })
  }
  updateEvent(ev: any, cartId: number, type: string) {
    let value = ev.target.value;
    if (value && type === 'update') {
      this.updateCartConfirmation(cartId, value);

    }
  }
  updateCartItem(item: any, event: Event) {
    if(!this.add) {
    return false;
    } else {
      if (event['detail'].checked) {
        this.total += item.totalPrice;
        let obj = {
          "providerId": item.providerId,
          "providerUserId": item.providerUserId,
          "providerName": item.providerName,
          "providerProfile": item.productProfile,
          "dealId": item.dealId,
          "amount": item.totalPrice,
          "currenyId": item.currencyId,
          "currencyName": item.currencyName
        }
        this.cartItem.push(obj);
      } else {
        this.total -= item.totalPrice;
        this.cartItem.forEach((element, index) => {
          if (element.cartId === item.cartId) {
            this.cartItem.splice(1, index)
          }
        });
      }
      if(this.total > 0) {
        this.isDisable = false
      } else {
        this.isDisable = true;
      }
    }
    
  }
  async updateCartConfirmation(cartId: number, value: number) {
    let alert = await this.alertCtrl.create({
      header: 'Confirmation',
      message: 'Do You want update the Quanity?',
      buttons: [
        {
          text: 'No',
          role: 'No',
          handler: () => {
            console.log('Cancel clicked');
          }
        },
        {
          text: 'Yes',
          handler: () => {
            this.providerProductService.updateProductsInCart(cartId, value).subscribe((res) => {
              console.log(res);
              this.messageService.presentToast(res.message);
              this.getCardList(false,'',1);
            })
          }
        }
      ]
    });
    await alert.present();
  }
  // changeStatus(cartId: number, status: string) {
  //   if(status === 'Waiting For Validation') {
  //     this.deleteConfirmation(cartId);
  //   }

  // }
  async deleteConfirmation(cartId: number) {
    let alert = await this.alertCtrl.create({
      header: 'Confirmation',
      message: 'Do you want abort the Product?',
      buttons: [
        {
          text: 'No',
          role: 'No',
          handler: () => {
            console.log('Cancel clicked');
          }
        },
        {
          text: 'Yes',
          handler: () => {
            this.removeFromCart(cartId);
          }
        }
      ]
    });
    await alert.present();
  }
  isCardActive() {
    this.bankAccountService.isActiveCardAvailable().subscribe(data => {
      console.log(data)
      this.cardActive = data;
    })
  }


   checkStatus(event,item) {
    if (event.detail.checked == true) {
      this.carkCheckeditems.push(item)
    }else{

    }
    }
  submit() {
    if(localStorage.getItem('GuidedVisite')=='true'){
      this.guidedvisitemessage.GuidedVisitemessage('Provider Payment','This button allows you to Provider Payment ')
      }else{
    if (this.cardActive === true) {
      let obj = {
        "restaurantId": localStorage.getItem('resturantId'),
        "restaurantName": localStorage.getItem('resturantName'),
        "restaurantProfile": localStorage.getItem('resturantProfile'),
        "providers": this.cartItem
      }
      this.bankAccountService.cartPay(obj).subscribe((res) => {
        this.messageService.presentToast(res.message);
        this.getCardList(false,'',1);
      })
    } else {
      this.cardInfo();
    }
  }

  }
  async cardInfo() {
    const modal = await this.modalCtrl.create({
      component: CardInfoComponent,
    });
    await modal.present();

    const { data } = await modal.onDidDismiss();
    if (data === true) {
      alert('test')
    }
  }


  RemoveitemCart(item){
    this.cartService.RemoveItemList(item.cartId).subscribe(result=>{
      if(result){
        this.getCardList(false,'',1);
      }
    })
  }



    // SearchFilterInhistory 
    async SearchFilterInhistory() {
      const modal = await this.modalCtrl.create({
        component: FilterItemStatusComponent,
        componentProps: {ScreenType:'ProviderCart' }
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      if (data) {
  
      }
    }

}
