import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';


const api: string = environment.baseUrl;

@Injectable({
    providedIn: 'root'
})
export class OrderService {
    constructor(private http: HttpClient) { }

    FindAllOrders(): Observable<[]> {
        let restaurantId = Number(sessionStorage.getItem('resturantId'));
        return this.http.get<[]>(api + '/api/Order/FindOrders?RestaurantId=' + restaurantId);
    }

    
    FindProgressOrders(orderByName: boolean, orderByTable: boolean,PageNumber,PageSize): Observable<[]> {
        let restaurantId = Number(sessionStorage.getItem('resturantId'));
        return this.http.get<[]>(api + '/api/Order/FindProgressOrders?RestaurantId=' + restaurantId +'&OrderByName=' + orderByName +'&OrderByTable=' + orderByTable+'&PageNumber='+PageNumber+'&PageSize='+PageSize);
    }
    FindTerminatedOrders(orderByName: boolean, orderByTable: boolean,PageNumber,PageSize): Observable<[]> {
        let restaurantId = Number(sessionStorage.getItem('resturantId'));
        return this.http.get<[]>(api + '/api/Order/FindTerminatedOrders?RestaurantId=' + restaurantId +'&OrderByName=' + orderByName +'&OrderByTable=' + orderByTable+'&PageNumber='+PageNumber+'&PageSize='+PageSize);
    }


    FindAchievedOrderList(orderByName: boolean, orderByTable: boolean,PageNumber,PageSize): Observable<[]> {
        let restaurantId = Number(sessionStorage.getItem('resturantId'));
        return this.http.get<[]>(api + '/api/Order/FindAchievedOrders?RestaurantId=' + restaurantId +'&OrderByName=' + orderByName +'&OrderByTable=' + orderByTable+'&PageNumber='+PageNumber+'&PageSize='+PageSize);
    }

    FindRunningOrders(id): Observable<any> {
        return this.http.get<any>(api + '/api/Order/FindRunningOrderForTable?EventId=' + id);
    }

    FindOrderDetails(id): Observable<any> {
        return this.http.get<any>(api + '/api/Order/FindOrderItemsForDiscount?OrderId=' + id);
    }

    FindDiscountDropdown(): Observable<any> {
        let restaurantId = Number(sessionStorage.getItem('resturantId'));
        return this.http.get<any>(api + '/api/AllClientOffer/FindDiscountDropdown?restaurantId=' + restaurantId);
    }


    FindOrderItemDetails(OrderId,ItemId,IsItem): Observable<any> {
        return this.http.get<any>(api + '/api/Report/FindOrderItemDetails?OrderId=' + OrderId+'&ItemId='+ItemId+'&IsItem='+IsItem);
    }
    FindNotificationDetails(ActionId,ActionName): Observable<any> {
        return this.http.get<any>(api + '/api/Notification/FindNotificationDetails?ActionId=' + ActionId+'&ActionName='+ActionName);
    }
    CreateManualDiscount(value): Observable<any> {
        let restaurantId = Number(sessionStorage.getItem('resturantId'));
        return this.http.post(api + '/api/Pay/AddManualDiscount', value);
    }
    DeleteManualDiscount(orderid): Observable<any> {
        let restaurantId = Number(sessionStorage.getItem('resturantId'));
        return this.http.delete(api + '/api/Pay/DeleteManualDiscount?orderid='+orderid);
    }

    createOrder(value): Observable<any> {
        let restaurantId = Number(sessionStorage.getItem('resturantId'));
        return this.http.post(api + '/api/Order/Create?RestaurantId=' + restaurantId, value);
    }
    ClearAllNotification(): Observable<any> {
        let restaurantId = Number(sessionStorage.getItem('resturantId'));
        return this.http.delete(api + '/api/Notification/ClearAllNotification?RestaurantId=' + restaurantId);
    }

    FindOrderPrice(id): Observable<any> {
        console.log(id)
        if(typeof id === 'number'){
            return this.http.get<any>(api + '/api/Order/FindOrderPrice?OrderId=' + id+'&IsTakeAway='+true);
        }else{
            return this.http.get<any>(api + '/api/Order/FindOrderPrice?EventId=' + id+'&IsTakeAway='+false);
        }
    }
    FindCustomerItems(id,CustomerName): Observable<any> {
        console.log(id)
        if(typeof id === 'number'){
            return this.http.get<any>(api + '/api/Order/FindCustomerItems?OrderId=' + id+'&CustomerName='+CustomerName);
        }else{
            return this.http.get<any>(api + '/api/Order/FindCustomerItems?EventId=' + id+'&CustomerName='+CustomerName);
        }
    }

    FindCart(id): Observable<any> {
        console.log(id)
        let restaurantId = Number(sessionStorage.getItem('resturantId'));
        if(typeof id === 'number'){
            return this.http.get<any>(api + '/api/Order/FindCart?OrderId=' + id+'&RestaurantId='+restaurantId);
        }else{
            return this.http.get<any>(api + '/api/Order/FindCart?EventId=' + id+'&RestaurantId='+restaurantId);
        }
    }

    

    AddItemsToOrder(request, id): Observable<any> {
        let restaurantId = Number(sessionStorage.getItem('resturantId'));
        return this.http.put<any>(api + '/api/Order/AddOrderItem?RestaurantId=' + restaurantId + '&OrderId=' + id, request);
    }
    AddItemsToOrderimPosMode(request, id): Observable<any> {
        let restaurantId = Number(sessionStorage.getItem('resturantId'));
        return this.http.put<any>(api + '/api/Order/AddOrderItemForPOSMode?RestaurantId=' + restaurantId + '&OrderId=' + id, request);
    }
    UpdateMenuToOrder(request, id): Observable<any> {
        let restaurantId = Number(sessionStorage.getItem('resturantId'));
        return this.http.put<any>(api + '/api/Order/UpdateMenu?RestaurantId=' + restaurantId + '&OrderId=' + id, request);
    }
    FindItemsOfMenu(menuId, orderId): Observable<any> {
        
        return this.http.get<any>(api + '/api/Order/FindOrderMenuItemsByMenu?MenuId=' + menuId + '&OrderId=' + orderId);
    }
    UpdateOrderItemStatus(orderItemId, statusId, orderId): Observable<any> {
        return this.http.put<any>(api + '/api/Order/UpdateOrderItemStatus?OrderItemId=' + orderItemId + '&StatusId=' + statusId + '&OrderId=' + orderId, {});
    }
    FindOrderItemOptions(orderItemId): Observable<any> {
        return this.http.get<any>(api + '/api/Order/FindOrderItemOptions?OrderItemId=' + orderItemId);
    }
    UpdateItemQuantity(orderItemId: number, qty: number): Observable<any> {
        ;
        return this.http.put(api + '/api/Order/UpdateItemQuantity?OrderItemId=' + orderItemId + '&Quantity=' + qty, {});
    }
    UpdateMenuQuantity(orderId: number, menuId: number, qty: number): Observable<any> {
  
        return this.http.put(api + '/api/Order/UpdateMenuQuantity?OrderId=' + orderId + '&menuId=' + menuId + '&Quantity=' + qty, {});
    }
    DeleteOrderItem(id): Observable<any> {
        return this.http.delete(api + '/api/Order/DeleteOrderItem?OrderItemId=' + id);
    }
    DeleteOrderMenu(orderId, menuId): Observable<any> {
        return this.http.delete(api + '/api/Order/DeleteMenu?OrderId=' + orderId + '&MenuId=' + menuId);
    }
    UpdateOrderItemOptions(orderItemId, accomapanimentsId, ingredientsId, data): Observable<any> {
        // query string
        let acc;
        let ing;
        if(accomapanimentsId) {
            acc = accomapanimentsId.map(function(el, idx) {
                return 'AccomapanimentsId[' + idx + ']=' + el;
            }).join('&');
        } 
        acc = acc ? acc : null
        if(ingredientsId) {
            ing = ingredientsId.map(function(el, idx) {
                return 'IngredientsId[' + idx + ']=' + el;
            }).join('&');
        } 
        ing = ing ? ing : null
        return this.http.put<any>(api + '/api/Order/UpdateOrderItemOptions?OrderItemId=' + orderItemId + '&' + acc +'&'+ing, data);
    }
    setOrderForAnotherCustomer(data: any): Observable<any> {
        if(data.menuId) {
            return this.http.put(api + '/api/Order/SetOrderForAnotherCustomer?OrderId=' + data.orderId  + '&MenuId=' + data.menuId + '&CustomerName=' + data.customerName, {});
        } else {
            return this.http.put(api + '/api/Order/SetOrderForAnotherCustomer?OrderId=' + data.orderId + '&OrderItemId=' + data.orderItemId  + '&CustomerName=' + data.customerName, {});
        }
        
    }


   CreateNote(model): Observable<any> {
        return this.http.post<any>(api + '/api/Order/AddNote',model);
    }
    UpdateNote(model): Observable<any> {
        return this.http.post<any>(api + '/api/Order/UpdateNote',model);
    }
    GetNote(orderId,itemId,isWaiter): Observable<any> {
        return this.http.get<any>(api + '/api/Order/FindOrderNotes?orderId='+orderId+'&isWaiter='+isWaiter+'&itemId='+itemId);
    }

    FindAllOrderItems(PageNumber,PageSize){
        let restaurantId = Number(sessionStorage.getItem('resturantId'));
        return this.http.get<any>(api + '/api/Order/FindAllOrderItems?RestaurantId=' + restaurantId+'&PageNumber='+PageNumber+'&PageSize='+PageSize );

    }

    FilterOrders(params) {
        console.log(params.toString())
        return this.http.get(api + '/api/Order/FilterOrders?' + params);
      }

    FindItemDetails(ItemId,orderId){
        return this.http.get<any>(api + '/api/Item/FindItemsForKitchenView?OrderId=' + orderId+'&ItemId='+ItemId);

    }

    GetCountOfItemsPaidInAnOrder(orderId){
        return this.http.get<any>(api + '/api/Order/GetCountOfItemsPaidInAnOrder?OrderId=' + orderId);

    }

  
    
}