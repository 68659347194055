import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ProfileCards } from '../../models/bank.model';
const api = environment.baseUrl;
@Injectable({
  providedIn: 'root'
})
export class BankAccountService {
  private subject = new Subject<boolean>();
  productPay$: Observable<boolean> = this.subject.asObservable();
  constructor(private http: HttpClient) { }
  isCreditCardSubmit(data: any) {
    this.subject.next(data);
  }
  isActiveCardAvailable() {
    let restaurantId = localStorage.getItem('resturantId');
    return this.http.put(api + "/api/BankAccount/IsActiveCardAvailable?RestaurantId=" + parseInt(restaurantId), {})
  }
  getAllCards(): Observable<ProfileCards[]> {
    let restaurantId = localStorage.getItem('resturantId');
    return this.http.get<ProfileCards[]>(api + "/api/BankAccount/FindAllCards?RestaurantId=" + parseInt(restaurantId))
  }
  CreateCardDetails(data: any): Observable<any> {
    console.log(data);
    let restaurantId = localStorage.getItem('resturantId');
    return this.http.post(api + "/api/BankAccount/CreateCreditCard?RestaurantId=" + parseInt(restaurantId), data)
  }

  CreateBrokerCreditCard(data: any): Observable<any> {
    console.log(data);
    let brokerOrganisationId = localStorage.getItem('brokerOrganisationId');
    return this.http.post(api + "/api/BankAccount/CreateBrokerCreditCard?borkerOrgid=" + brokerOrganisationId, data)
  }

  updateActiveCard(id: any, isActive: any): Observable<any> {
    let obj = {
      id: id,
      IsActive: isActive
    }
    let restaurantId = localStorage.getItem('resturantId');
    return this.http.put(api + "/api/BankAccount/UpdateIsActive" + "?RestaurantId=" + parseInt(restaurantId) + "&Id=" + id + "&IsActive=" + isActive, obj)
  }
  removeCard(id: any): Observable<any> {
    return this.http.delete(api + "/api/BankAccount/RemoveCard" + "?Id=" + id)
  }
  cartPay(body: any): Observable<any> {
    return this.http.post(api + '/api/Deal/Pay', body)
  }
  FindBankAccount(): Observable<any> {
    return this.http.get<any>(api + "/api/BankAccount/FindBankAccount")
  }

  FindMapConfigurationsForBroker(){
    let brokerOrganisationId = localStorage.getItem('brokerOrganisationId');
    return this.http.get<any>(api + "/api/Mapping/FindMapConfigurationsForBroker?brokerOrganisationId="+brokerOrganisationId)
  }
  getMapConf(): Observable<any> {
    let RestaurantId = localStorage.getItem('resturantId');
    return this.http.get<any>(api + "/api/Mapping/FindMapConfigurations?RestaurantId="+RestaurantId)
  }


  createBankDetails(password: string, accountToken: string, bankToken: string, accountHolderName: string, accountHolderType: string, bankName: string, bankCountry: string, currency: string, last4: string): Observable<any> {
    const reqdata = {
      password: password,
      accountToken: accountToken,
      bankToken: bankToken,
      accountHolderName: accountHolderName,
      accountHolderType: accountHolderType,
      bankName: bankName,
      bankCountry: bankCountry,
      currency: currency,
      last4: last4
    };
    console.log(reqdata)
    return this.http.post(api + "/api/BankAccount/CreateBankAccount", reqdata)
  }
  updateBankDetails(password: string, accountToken: string, bankToken: string, accountHolderName: string, accountHolderType: string, bankName: string, bankCountry: string, currency: string, last4: string): Observable<any> {
    const reqdata = {
      password: password,
      accountToken: accountToken,
      bankToken: bankToken,
      accountHolderName: accountHolderName,
      accountHolderType: accountHolderType,
      bankName: bankName,
      bankCountry: bankCountry,
      currency: currency,
      last4: last4
    };
    console.log(reqdata)
    return this.http.post(api + '/api/BankAccount/UpdateBankAccount', reqdata, { responseType: 'text' })
  }
  UpdateIsActiveBankAccount(id: any, isActive: any): Observable<any> {
    let obj = {
      id: id,
      IsActive: isActive
    }
    let restaurantId = localStorage.getItem('resturantId');
    return this.http.put(api + "/api/BankAccount/UpdateIsActiveBankAccount" + "?Id=" + id + "&IsActive=" + isActive, obj)
  }
  deleteBankAccount(id: any): Observable<any> {
    return this.http.delete(api + "/api/BankAccount/RemoveBankAccount" + "?Id=" + id + "&Password=" + localStorage.getItem('password'))
  }
}
