import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class NotificationsUIColorsService {
  constructor() {}

  leftbodybordercolor(value) {
    // Ingredient
    if (value.action === 'Ingredient') {
      if (value.noOfDaysLeft == 2) {
        return 'leftbodybordercolorRed';
      } else if (value.noOfDaysLeft == 5) {
        return 'leftbodybordercolororange';
      }
      
      // OfferBucket
    } else if (value.action === 'OfferBucket') {
      return 'leftbodybordercolorYellow';

      // stockUpdate
    } else if (value.action === 'stockUpdate') {
      return 'leftbodybordercolorYellow';

      // AcceptedDealProvider
    } else if (value.action === 'AcceptedDealProvider') {
      return 'leftbodybordercolorYellow';

      // Order
    } else if (value.action === 'Order') {
      return 'leftbodybordercolorYellow';

      // Planning
    } else if (value.action === 'Planning') {
      return 'leftbodybordercolorblock';

      // DealChat
    } else if (value.action === 'DealChat') {
      return 'leftbodybordercolorblock';

      // DealRequest
    } else if (value.action === 'DealRequest') {
      return 'leftbodybordercolorblock';

      // OrderItems
    } else if (value.action === 'OrderItems') {
      if (value.statusName === 'Delivered') {
        return 'leftbodybordercolorgreen';
      } else if(value.statusName === 'In procress' ||value.statusName === 'Available' ||value.statusName==='Order In Progress'||value.statusName==='Order in Progress') {
        return 'leftbodybordercolorblue';
      }else if(value.statusName === 'Canceld'||value.statusName === 'Ordered'){
        return 'leftbodybordercolorRed';
      }

      // Payment
    } else if (value.action === 'Payment'||value.action ==='DealPayment') {
      if (value.statusName == 'Paid') {
        return 'leftbodybordercolorgreen';
      } else if(value.statusName != 'Paid'){
        return 'leftbodybordercolorRed';
      }else{
        return 'leftbodybordercolorblock';
      }

      // TableManagement
    } else if (value.action === 'TableManagement') {
      if (value.statusName === 'Available') {
        return 'leftbodybordercolorblue';
      } else if(value.statusName === 'In procress'|| value.statusName==='Order In Progress'|| value.statusName==='Order in Progress') {
        return 'leftbodybordercolorYellow';
      }else if(value.statusName === 'Waiting'){
        return 'leftbodybordercolorRed';
      }else{
        return 'leftbodybordercolorblock';
      }
    }
  }
}
