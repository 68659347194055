import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { IngredientDetailComponent } from 'src/app/pages/food-management/ingredient-detail/ingredient-detail.component';
import { NotificationsItemsListComponent } from '../notifications-items-list/notifications-items-list.component';
import { ProviderCartPage } from 'src/app/pages/suppliers-management/provider-cart/provider-cart.page';
import { CartService } from 'src/app/shared/services/cart-service.service';
import { MessageService } from 'src/app/shared/services/message-service.service';
import { SignalRService } from 'src/app/shared/services/api/signal-rservice.service';
import { NotificationsUIColorsService } from 'src/app/shared/services/notifications-ui-colors.service';
import { OrderService } from 'src/app/shared/services/api/orders.service';
import { MessagesService } from 'src/app/pages/auth/Guided_Visite/messages.service';

@Component({
  selector: 'app-show-notifications',
  templateUrl: './show-notifications.component.html',
  styleUrls: ['./show-notifications.component.scss'],
})
export class ShowNotificationsComponent implements OnInit {
  notificationlist: any = [];
  showlist = true;
  unseeNotificationsIds = [];
  NumberOfRecord = 20;
  PageNumber = 1;
  constructor(
    private readonly modalCtrl: ModalController,
    private cartService: CartService,
    private messageService: MessageService,
    private signalRService: SignalRService,
    public UIcolors: NotificationsUIColorsService,
    private orderService: OrderService,
    public guidedvisitemessage:MessagesService,
    
  ) {}

  ngOnInit() {
    this.getAllnotifications();
  }

  clocepopup() {
    this.unseeNotificationsIds = [];
    if (this.notificationlist.length != 0) {
      this.notificationlist.forEach((element) => {
        console.log(element);
        if (
          element?.isSeen == false &&
          element.action != 'OfferBucket' &&
          element.action != 'stockUpdate'
        ) {
          console.log(element);
          this.unseeNotificationsIds.push(element.id);
        }
      });
      let unique = [...new Set(this.unseeNotificationsIds)];
      this.modalCtrl.dismiss(unique);
    } else {
      this.modalCtrl.dismiss([]);
    }
  }

  loadData(event) {
    this.PageNumber = this.PageNumber + 1;
    this.getAllnotifications();
    setTimeout(() => {
      event.target.complete();
    }, 100);
  }

  ClearAllNotifications() {
    if(sessionStorage.getItem('GuidedVisite')=='true'){
      this.guidedvisitemessage.GuidedVisitemessage('Clear Notification','This button allows you to Clear All Notification')
      }else{
        this.orderService.ClearAllNotification().subscribe((result) => {
          if (result) {
            this.notificationlist.length = 0;
            this.getAllnotifications();
          }
        });
      }

  }

  ClearNotifications(id) {
    if(sessionStorage.getItem('GuidedVisite')=='true'){
      this.guidedvisitemessage.GuidedVisitemessage('Remove Notification','This button allows you to Remove Notification')
      }else{
    this.DeleteNotification(id);
      }
  }

  DeleteNotification(data) {
    this.signalRService.DeleteNotification(data).subscribe((result) => {
      if (result) {
        setTimeout(() => {
          this.notificationlist = [];
          this.PageNumber = 1;
          this.getAllnotifications();
        }, 10);
      }
    });
  }

  getAllnotifications() {
    this.signalRService
      .FindAllNotifications(this.PageNumber, this.NumberOfRecord)
      .subscribe((result: any) => {
        if (result) {
          result.notifications.data.forEach((element) => {
            this.notificationlist.push(element);
          });
        }
      });
  }

  checkhurwithnoOfDaysLeft(value) {
    if (value == 2) {
      return '48';
    } else if (value == 5) {
      return '120';
    }
  }

  assignCssClass(value) {
    if (value == 2) {
      return 'URGENTstyle';
    } else if (value == 5) {
      return 'IMPORTANTstyle';
    }
  }

  tablestatustyle(status) {
    if (
      status === 'Payment Done' ||
      status === 'Partially Paid' ||
      status === 'Delivered'
    ) {
      return 'Greencolor';
    } else if (status === 'Available') {
      return 'bluecolor';
    } else if (status === 'On going' || status == 'Order In Progress'|| status == 'Order in Progress') {
      return 'Yellocolor';
    } else if (status === 'Ordered') {
      return 'redcolor';
    } else if (status === 'Waiting For The Order') {
      return 'orangecolor';
    } else if (status === 'Reserved') {
      return 'Greencolor';
    }
  }

  leftbodybordercolor(value) {
    return this.UIcolors.leftbodybordercolor(value);
  }

  calculateDiff(value) {
    if (value.action == 'Offers') {
      let date1 = new Date(value.offerEndDate);
      let date2 = new Date(value.offerStartDate);
      let days = Math.floor(
        (date1.getTime() - date2.getTime()) / 1000 / 60 / 60 / 24
      );
      return days;
    }
  }

  keywordstyle(value) {
    if (value == 2) {
      return 'keywordstylered';
    } else if (value == 5) {
      return 'keywordstyleorange';
    }
  }

  gotonevigation(value) {
    if (value.action == 'Ingredient') {
      this.gotoIngredientScreen(value);
    } else if (value.action == 'OfferBucket') {
      this.ShowListofProviderCartPage(value);
    }
  }

  async gotoIngredientScreen(selectedIngredient) {
    const modal = await this.modalCtrl.create({
      component: IngredientDetailComponent,
      componentProps: {
        ingredient: selectedIngredient,
      },
    });
    await modal.present();
    const { data } = await modal.onDidDismiss();
    if (data) {
    }
  }

  async ShowListofingredients(x) {
    const modal = await this.modalCtrl.create({
      component: NotificationsItemsListComponent,
      componentProps: { details: { data: x, screentype: 'IngredientsList' } },
    });
    await modal.present();
    const { data } = await modal.onDidDismiss();
    if (data) {
    }
  }

  async ShowListofProviderCartPage(value) { 
    console.log(value);
    value.offerBuckets.ingredients.forEach((element) => {
      let product = {
        restaurantId: sessionStorage.getItem('resturantId'),
        productId: element.productId,
        providerUserId: value.providerUserId,
        providerName: value.providerName,
        quantity: element.quantity,
        productName: element.productName,
        productProfile: element.profilePicture,
        minQuantity: 1,
        unitName: element.unit,
        referenceId: '765842678',
        note: 0,
        offerId: value.offerBuckets.offerId,
        offerName: value.offerBuckets.offerName,
        isOffer: true,
      };

      this.cartService.addProduct(product).subscribe(async (res) => {
        if (res) {
          this.messageService.presentToast(res.message);
          const modal = await this.modalCtrl.create({
            component: ProviderCartPage,
            componentProps: {},
          });
          await modal.present();
          const { data } = await modal.onDidDismiss();
          if (data) {
          }
        }
      });
    });
  }

  calculateTotalHours(value) {
    const start = new Date(value.notificationSendAt);
    const end = new Date();
    const timeDifference = Math.abs(end.getTime() - start.getTime());
    const minutes = Math.floor((timeDifference / 1000 / 60) % 60);
    const hours = Math.floor((timeDifference / 1000 / 60 / 60) % 24);
    const days = Math.floor(timeDifference / 1000 / 60 / 60 / 24);
    console.log(minutes);
    console.log(hours);
    console.log(days);
    if (days == 0 && hours != 0) {
      return hours + ' h ago';
    } else if (days != 0 && hours != 0) {
      return days + ' d ago';
    } else if (days == 0 && hours == 0) {
      return minutes + ' m ago';
    }
  }

  GotoRedirection(value) {
    if (value.statusName === 'Waiting For Validation') {
    } else if (value.statusName === 'In Progress') {
      this.GotoCart();
    } else if (value.statusName === 'Paid') {
    }
  }

  async GotoCart() {
    const modal = await this.modalCtrl.create({
      component: ProviderCartPage,
    });
    await modal.present();
  }

  AcceptOrRejectorder(x, value) {
    if(sessionStorage.getItem('GuidedVisite')=='true'){
      if(value==0){
        this.guidedvisitemessage.GuidedVisitemessage('Deal Request','This button allows you to reject Deal Request ')
      }else if(value==1){
        this.guidedvisitemessage.GuidedVisitemessage('Deal Request','This button allows you to accept Deal Request ')

      }
      }else{
    if (x.statusName == 'Waiting For Validation'||x.statusName == 'WaitingForValidate') {
      const data = {
        managerId: x.fromUserId,
        providerId: x.providerId,
        providerUserId: x.toUserId,
        providerName: x.providerName,
        providerProfile: x.providerProfile,
        cartId: x.cartId,
        productName: x.productName,
        productProfile: x.productProfile,
        isAccept: value == 0 ? true : false,
        restaurantId: x.restaurantId,
        restaurantName: x.restaurantName,
        restaurantMediaItemId: x.restaurantMediaItemId,
        restaurantProfile: x.restaurantProfile,
      };
      console.log(x);
      this.cartService.AcceptRejectDeal(data).subscribe((result) => {
        if (result) {
          this.getAllnotifications();
        }
      });
    }
  }
  }

  async ShowListoforderitems(x) {
    const modal = await this.modalCtrl.create({
      component: NotificationsItemsListComponent,
      componentProps: { details: { data: x, screentype: 'orderdetails' } },
    });
    await modal.present();
    const { data } = await modal.onDidDismiss();
    if (data) {
    }
  }
}
