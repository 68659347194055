import { Component, Input, OnInit } from '@angular/core';
import { ModalController, PopoverController } from '@ionic/angular';
import { IngredientService } from 'src/app/shared/services/api/ingredient.service';
import { ProviderProductService } from 'src/app/shared/services/api/provider-product.service';
import { MessageService } from 'src/app/shared/services/message-service.service';

@Component({
  selector: 'app-ingrediant-list',
  templateUrl: './ingrediant-list.component.html',
  styleUrls: ['./ingrediant-list.component.scss'],
})
export class IngrediantListComponent implements OnInit {
  @Input()ingredient;
  searchedProviders: any;
  TotalQuantity: any = 0;
  MinPrice: any = 0;
  MaxPrice: any = 0;
  Distance: any = 0;

  constructor(
    private readonly modalCtrl: ModalController,
    private popoverCtrl: PopoverController,
    private messageService: MessageService,
    private ingredientService: IngredientService,
    private providerProductService: ProviderProductService,
  ) { }

  ngOnInit() {
    console.log(this.ingredient)
    this.searchFilter()
  }

  closepopup(){
    this.modalCtrl.dismiss()
  }



  searchFilter() {
    let query = 'RestaurantId=' + sessionStorage.getItem('resturantId') + '&ProductName=' + this.ingredient.ingredientName + '&TotalQuantity=' + this.TotalQuantity + '&MinPrice=' + this.MinPrice + '&MaxPrice=' + this.MaxPrice + '&Distance=' + this.Distance + '&NumberOfRecord=' + 200 + '&PageNumber=' + 1;
    this.providerProductService.FilterProviders(query).subscribe(res => {
      console.log(res);
    if(res){
      this.searchedProviders = res;
    }
    });
  }

}
