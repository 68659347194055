import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ModalController, PopoverController } from '@ionic/angular';
import moment from 'moment';
import { IngredientService } from 'src/app/shared/services/api/ingredient.service';
import { MessageService } from 'src/app/shared/services/message-service.service';
import { FilterComponent } from './filter/filter.component';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss'],
})
export class HistoryComponent implements OnInit {
  @Input() data
  IngredientQuantityHistoryList = [];
  IngredientOrderHistoryList = [];
  IngredientExpriyHistoryList = [];
  ingredientId: any;
  screentype = ''
  ingredient: any;
  copyDate: any;
  todayDate: any;

  constructor(
    private readonly modalCtrl: ModalController,
    private popoverCtrl: PopoverController,
    private messageService: MessageService,
    private ingredientService: IngredientService,
    private datePipe: DatePipe,

  ) { }

  ngOnInit() {
    this.ingredient = this.data.ingredient
    this.screentype = this.data.screenType
    if (this.screentype == 'Quantityexpiry') {
      this.ingredientId = this.data?.ingredient.id ? this.data?.ingredient.id : this.data?.ingredient.ingredientId
      console.log(this.data)
      this.GetIngredientexpiry()
    } else if (this.screentype == 'QuantityHistory') {
      this.ingredientId = this.data?.ingredient.id ? this.data?.ingredient.id : this.data?.ingredient.ingredientId
      console.log(this.data)
      this.GetIngredientHIstory()
    }

  }

clearpopup(){
  this.modalCtrl.dismiss()
}
  GetIngredientHIstory() {
    const StartDate = new Date(new Date().getFullYear(), 0, 1);;
    const EndDate = new Date();
    this.ingredientService.FindAllIngredientHistory(this.ingredientId, this.datePipe.transform(StartDate, 'yyyy-MM-dd'), this.datePipe.transform(EndDate, 'yyyy-MM-dd'),true,true).subscribe((result) => {
      if (result) {
        console.log(result.data)
        this.IngredientQuantityHistoryList = result.data
      }
    })
  }





  GetIngredientexpiry() {
    this.ingredientService.GetIngredientexpiry(this.ingredientId).subscribe((result: any) => {
      if (result) {
        console.log(result)
        this.IngredientExpriyHistoryList.push(result)
      }
      console.log(result)
    })
  }
 


 async searchAndFilterModal() {
  const modal = await this.modalCtrl.create({
    component: FilterComponent
  });
  await modal.present();
  const { data } = await modal.onDidDismiss();
  if(data){
    console.log(data)
    this.ingredientService.FindAllIngredientHistory(this.ingredientId, this.datePipe.transform(data.startDate, 'yyyy-MM-dd'), this.datePipe.transform(data.endDate, 'yyyy-MM-dd'),data.IsOrder,data.IsStock).subscribe((result) => {
      if (result) {
        console.log(result.data)
        this.IngredientQuantityHistoryList = result.data
      }
    })
  } 

}

}
