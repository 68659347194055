import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertController, ModalController } from '@ionic/angular';
import { Calory } from 'src/app/shared/models/calory.model';
import { Ingredient } from 'src/app/shared/models/ingredient.model';
import { Unit } from 'src/app/shared/models/unit.model';
import { CaloryService } from 'src/app/shared/services/api/calory.service';
import { CategoryService } from 'src/app/shared/services/api/category.service';
import { ConstantDataService } from 'src/app/shared/services/api/constant-data.service';
import { IngredientService } from 'src/app/shared/services/api/ingredient.service';
import { UnitService } from 'src/app/shared/services/api/unit.service';
import { MessageService } from 'src/app/shared/services/message-service.service';
import { IngredientQuantityUpdateComponent } from '../ingredient-quantity-update/ingredient-quantity-update.component';
import { LeanrestoGalleryComponent } from 'src/app/components/leanresto-gallery/leanresto-gallery.component';
import { MessagesService } from '../../auth/Guided_Visite/messages.service';
import { AddNewTagComponent } from 'src/app/components/add-new-tag/add-new-tag.component';

@Component({
  selector: 'app-ingredient-edit',
  templateUrl: './ingredient-edit.component.html',
  styleUrls: ['./ingredient-edit.component.scss'],
})
export class IngredientEditComponent implements OnInit {
  isSubmitted: boolean = false;
  @Input() ingredient:any;
  units: Unit[];
  calories: Calory[];
  form: FormGroup;
  suppTypes: any[];
  copyImagePath: any;
  imagePath: any;
  imageFile: File;
  imageLibraryId=null

  constructor(
    public modalCtrl: ModalController,
    private formBuilder: FormBuilder,
    private constantDataService: ConstantDataService,
    private unitService: UnitService,
    private caloryService: CaloryService,
    private ingredientService: IngredientService,
    private categoryService: CategoryService,
    private messageService: MessageService,
    private alertCtrl: AlertController,
    public guidedvisitemessage:MessagesService
    ) { }

  ngOnInit() {
    this.imagePath = '';
    this.constantDataService.findSupplierTypeService().subscribe(data => this.suppTypes = data);
    this.unitService.getAllUnitsService().subscribe(data => this.units = data);
    this.caloryService.getAllCaloriesService().subscribe(data => this.calories = data);
    this.form = this.formBuilder.group({
      id: [''],
      ingredientName: ['', Validators.required],
      calories: ['', Validators.required],
      quantity: ['', Validators.required],
      unitId: ['', Validators.required],
      statusFlag: ['', Validators.required],
      limit: ['', Validators.required],
      supplierTypeId: ['', Validators.required],
      isOrderTracking: [true],
      expiryDate:['',Validators.required],
      TagViewModels: this.formBuilder.array([]), 

    });
     console.log(this.ingredient)
    this.form.patchValue({
      id: this.ingredient.id,
      expiryDate: this.ingredient.expiryDate,
      ingredientName: this.ingredient.ingredientName,
      calories: this.ingredient.calories,
      quantity: this.ingredient.quantity,
      unitId: this.ingredient.unitId,
      statusFlag: this.ingredient.statusFlag,
      limit: this.ingredient.limit,
      supplierTypeId: this.ingredient.supplierTypeId,
      isOrderTracking: this.ingredient?.isOrderTracking,
    });
    this.ingredient.unitName = this.ingredient.unitName;
    this.ingredient.supplierTypeName = this.ingredient.supplierTypeName
    this.imagePath = this.ingredient.profile;
    if(this.ingredient?.tagViewModels.length!=0){
      this.ingredient?.tagViewModels.forEach((element,index) => {
      this.addtageBody()
        this.gettageBody().at(index).get('tagType')?.setValue(element?.tagType)
        this.gettageBody().at(index).get('fieldType')?.setValue(element?.fieldType)
        this.gettageBody().at(index).get('dropdownValues')?.setValue(element?.getDropdownValues)
        this.gettageBody().at(index).get('fieldValue')?.setValue(element?.fieldvalue.toString())
        this.gettageBody().at(index).get('fieldName')?.setValue(element?.fieldName)
        this.gettageBody().at(index).get('tagTableId')?.setValue(element?.tagTableId)
      });
    }
  }




  // tages 
 
gettageBody() {
  return this.form.get('TagViewModels') as FormArray;
}
addtageBody() {
  this.gettageBody().push(this.createtageBody());
}
fieldname(i: number) {
  return this.gettageBody().at(i).get('fieldName')?.value;
}
fieldMandatory(i: number) {
  return this.gettageBody().at(i).get('Mandatory')?.value;
}
dropdownvaluetotal(i: number) {
  return this.gettageBody().at(i).get('dropdownvaluetotal')?.value.split(',');
}
removetag(i){
  this.gettageBody().removeAt(i)
}
fieldType(i: number) {
  return this.gettageBody().at(i).get('fieldType')?.value;
}
createtageBody(): FormGroup {
  return this.formBuilder.group({
    Mandatory:[false],
    tagTableId: 0,
    fieldName: '',
    fieldType: '', 
    fieldValue: '',
    dropdownValues: '',
    dropdownvaluetotal: '',
    tagType:''
  });
}

async addNewTag() {
  const modal = await this.modalCtrl.create({
    component: AddNewTagComponent 
  }); 
  await modal.present();
  const { data } = await modal.onDidDismiss();
  if (data) {
    console.log(data)
    this.addtageBody()
    var lenghtfortagbody=this.form.value.TagViewModels.length
    console.log(lenghtfortagbody)
    this.gettageBody().at(lenghtfortagbody-1).get('tagType')?.setValue(data.tagType)
    this.gettageBody().at(lenghtfortagbody-1).get('fieldType')?.setValue(data.fieldType)
    this.gettageBody().at(lenghtfortagbody-1).get('dropdownValues')?.setValue(data.Adddropdownvalue)
    this.gettageBody().at(lenghtfortagbody-1).get('fieldValue')?.setValue(data.fieldvalue.toString())
    this.gettageBody().at(lenghtfortagbody-1).get('fieldName')?.setValue(data.fieldName)
    this.gettageBody().at(lenghtfortagbody-1).get('Mandatory')?.setValue(data.Mandatory)
    console.log(this.form.value.TagViewModels)
  }
}


  get errorControl() {
    return this.form.controls;
  }

  isFormValid() {
    return this.form.invalid;
  }

  setImage(ev: any) {
    const reader = new FileReader();
    this.imageFile = ev.target.files[0];
    reader.readAsDataURL(ev.target.files[0]);
    reader.onload = (event) => {
      this.imagePath = reader.result;
    };


    // if( this.imageFile) {
    //   this.editProfileConfirmation();
    // }
  }
  uploadImage() {
    if(sessionStorage.getItem('GuidedVisite')=='true'){
      this.guidedvisitemessage.GuidedVisitemessage('Update Profile','This button allows you to Update Profile')
      }else{
    console.log(this.imageFile)
    const formData = new FormData();
    formData.append('file', this.imageFile);
    this.ingredientService.addIngredientProfile(formData, this.ingredient.id).subscribe((res: any) => {
      this.messageService.presentToast('Profile picture added');
      this.ingredient.mediaItemId = Number(res.message);
      this.categoryService.loadingData(true, 'ingredient');
      if (res.message) {
        this.submitForm();
      }
    });
  }
  }
  removeProfile() {
    if(sessionStorage.getItem('GuidedVisite')=='true'){
      this.guidedvisitemessage.GuidedVisitemessage('Remove Profile','This button allows you to Remove Profile')
      }else{
    this.imagePath = null;
    this.imageFile = null;
      }
  }
  removeImage() {
    this.imagePath = null;
    this.imageFile = null;
    this.ingredientService.deleteIngredientProfile(this.ingredient.mediaItemId ? this.ingredient.mediaItemId : 0, this.ingredient.id).subscribe((res: any) => {
      this.messageService.presentToast(res.message);
      this.categoryService.loadingData(true, 'ingredient');
      this.ingredient.mediaItemId = null;
      if (res.message) {
        this.submitForm()
      }
    })
  }
  editImage() {
    const formData = new FormData();
    formData.append('file', this.imageFile);
    this.ingredientService.updateIngredientProfile(formData, this.ingredient.mediaItemId ? this.ingredient.mediaItemId : 0, this.ingredient.id).subscribe((res: any) => {
      this.messageService.presentToast(res.message);
      this.categoryService.loadingData(true, 'ingredient');
      if (res.message) {
        this.submitForm()
      }
    });
  }

  unitChanged(ev: any) {
    let value = ev.detail.value;
    let unit = this.units.filter(ele => ele.id === value);
    if (unit.length > 0) {
      this.ingredient.unitName = unit[0].name
    }
  }
  supplierChanged(ev: any) {
    let value = ev.detail.value;
    let supply = this.suppTypes.filter(ele => ele.id === value);
    if (supply.length > 0) {
      this.ingredient.supplierTypeName = supply[0].name
    }
  }
  submitForm() {
    const tagViewModels = this.form.value.TagViewModels || []; // Ensure TagViewModels is not undefined or null
    const updatedTagViewModels = tagViewModels.map(element => {
      // Return a new object with fieldValue converted to string
      return {
        ...element, // Copy existing properties
        fieldValue: element.fieldValue.toString() // Convert fieldValue to string
      };
    });
    console.log(this.ingredient.tagViewModels)
    const ingredient= {
      id: this.ingredient.id,
      imageLibraryId:this.imageLibraryId==null?null:this.imageLibraryId,
      ingredientName: this.form.value.ingredientName,
      calories: this.form.value.calories,
      quantity: this.form.value.quantity,
      unitId: this.form.value.unitId,
      statusFlag: this.form.value.statusFlag,
      limit: this.form.value.limit,
      supplierTypeId: this.form.value.supplierTypeId,
      isOrderTracking:this.form.value.isOrderTracking,
      expiryDate:this.form.value.expiryDate,
      mediaItemId: this.ingredient.mediaItemId ? this.ingredient.mediaItemId : null,
      tagViewModels:updatedTagViewModels
    };

    console.log(ingredient)

    this.ingredientService.updateIngredientService(ingredient).subscribe(data => {
      this.messageService.presentToast(data.message);
      this.categoryService.loadingData(true, 'ingredient');
      this.modalCtrl.dismiss(true);
    });
  }
  onSubmit() {
    if(this.form.value.TagViewModels.length!=0){
      this.form.value.TagViewModels.map((element)=>{
        return element.fieldValue.toString()
      })
    }
    if(sessionStorage.getItem('GuidedVisite')=='true'){
      this.guidedvisitemessage.GuidedVisitemessage('Update','This button allows you to Update ingredient')
      }else{

    this.ingredient.profile = this.imagePath;
    this.ingredient.mediaItemId = this.ingredient.mediaItemId;
    this.isSubmitted = true;
    if (this.ingredient.mediaItemId && this.ingredient.profile && this.imageFile) {
      this.editImage();
    } else if (this.ingredient.mediaItemId && !this.ingredient.profile) {
      this.removeImage();
    } else if (!this.ingredient.mediaItemId && this.ingredient.profile && this.imageFile) {
      this.uploadImage();
    } else {
      this.submitForm()
    }
  }
  }




  async imageUploadclick() {
    console.log('log')
    const popover = await this.modalCtrl.create({
      component: LeanrestoGalleryComponent,
      cssClass: 'scrollable',
      componentProps: { data:{name:'Ingredients'}}
    });
    await popover.present();
    const { data } = await popover.onDidDismiss();
    if(data){
      console.log(data)
      this.imagePath=data.file
      this.imageLibraryId=data.id
    }
  }

 




  async ingredientquantityupdate() {
    console.log(this.ingredient)
    const modal = await this.modalCtrl.create({
      component: IngredientQuantityUpdateComponent,
      componentProps: { data: this.form.value }
    });
    await modal.present();
    const { data } = await modal.onDidDismiss();
    if (data) {
      console.log(data)
      this.form.get('quantity').setValue(data[0].quantity)
      this.form.get('expiryDate').setValue(data[0].expiryDate)
      this.onSubmit()
    }
  }
}




