import { Injectable } from '@angular/core';
import { HubConnectionBuilder, HubConnection } from '@microsoft/signalr';
import * as signalR from '@aspnet/signalr';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';


const api = environment.baseUrl;


@Injectable({
  providedIn: 'root'
})
export class  SignalRService {
  private connection: signalR.HubConnection;
  connectionEstablished$ = new Subject<Boolean>();
  connectionEstablished = this.connectionEstablished$.asObservable(); 

  public loginToken: any

  constructor(private http: HttpClient) {
    this.loginToken = sessionStorage.getItem('access_token');

  }
  connect() {
    if (!this.connection) {
      this.connection = new signalR.HubConnectionBuilder()
        .withUrl('http://leanresto-dev.eu-west-3.elasticbeanstalk.com/chatHub', { accessTokenFactory: () => this.loginToken })
        .configureLogging(signalR.LogLevel.Information)
        .build();
      this.connection.start().then(() => {
        console.log('Hub connection started');
        this.connection.on("ReceiveMessage", (user, message) => {
            if(user||message){
              console.log(user)
              console.log(message)
              this.connectionEstablished$.next(user);
          }
          });
      }).catch(err => console.log(err));
    }
  }

  disconnect() {
    if (this.connection) {
      this.connection.stop();
      this.connection = null;
      console.log('Hub connection stop');
    }
  }


   FindAllNotifications(PageNumber,NumberOfRecord){
    const params = new HttpParams()
    .append('NumberOfRecord', NumberOfRecord)
    // .append('RestaurantId', sessionStorage.getItem('resturantId'))
    .append('PageNumber', PageNumber)
    return this.http.get(api + '/api/Notification/FindAllNotifications',{params});
}

UnSeenNotificationscount(){
  return this.http.get(api + '/api/Notification/FindNotificationsCount');
}

UpdateseenotificationsList(Ids){
  return this.http.put(api + '/api/Notification/UpdateNotificationSeenList',Ids);
}

DeleteNotification(Ids){
  return this.http.delete(api + '/api/Notification/DeleteNotification?Ids='+Ids);
}

}
