import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { IonicSelectableComponent } from 'ionic-selectable';
import { IngredientService } from 'src/app/shared/services/api/ingredient.service';

@Component({
  selector: 'app-leanrestro-gallery-filter-cretion',
  templateUrl: './leanrestro-gallery-filter-cretion.component.html',
  styleUrls: ['./leanrestro-gallery-filter-cretion.component.scss'],
})
export class LeanrestroGalleryFilterCretionComponent implements OnInit {
  @ViewChild('VerticleIds') VerticleIds: IonicSelectableComponent;
  @ViewChild('SubVerticleIds') SubVerticleIds: IonicSelectableComponent;
  form: FormGroup;
  @Input() ScreenType;
  notevalue = ''
  ListOfVerticleIds = [];
  ListOfSubVerticleIds = [];
  selectedVerticleIds = [];
  selectedSubVerticleIds = [];
  constructor(
    public readonly modalCtrl: ModalController,
    private formBuilder: FormBuilder,
    private ingredientService: IngredientService,
  ) {
    this.form = this.formBuilder.group({
      VerticleIds: [null],
      SubVerticleIds: [null],
    });
  }

  ngOnInit() {
    console.log(this.ScreenType)
    this.ingredientService.GetAllSubVerticals().subscribe((result) => {
      if (result) {
        this.ListOfSubVerticleIds = result
      }
      console.log(result)
    })
    this.ingredientService.GetAllVerticals().subscribe((result) => {
      if (result) {
        this.ListOfVerticleIds = result
      }
      console.log(result)
    })

  }



  onSubmit() {
    this.ingredientService.GetFilterImagesByIds(this.selectedVerticleIds,this.selectedSubVerticleIds,this.notevalue).subscribe((result)=>{
      if(result){
        this.modalCtrl.dismiss(result)
      }
      console.log(result)
    })

  }
















  // --------0000000000000---------- 
  toggleItems() {
    this.VerticleIds.toggleItems(this.VerticleIds?.itemsToConfirm.length ? false : true);
  }
  clear() {
    this.VerticleIds.clear();
    this.VerticleIds.close();
  }
  formatPorts(allData) {
    console.log(allData)
    return allData.map(port => port.name).join(',');
  }
  confirm() {
    this.VerticleIds.confirm();
    if (this.VerticleIds._itemsToConfirm.length > 0) {
      this.VerticleIds._itemsToConfirm.forEach((ele) => {
        this.selectedVerticleIds.push(ele.id);
      });
    }
    this.form.get('VerticleIds').setValue(this.selectedVerticleIds)
    this.VerticleIds.close();
  }
  // --------111111111-------- 
  toggleItems1() {
    this.SubVerticleIds.toggleItems(this.SubVerticleIds?.itemsToConfirm.length ? false : true);
  }
  clear1() {
    this.SubVerticleIds.clear();
    this.SubVerticleIds.close();
  }
  confirm1() {
    this.SubVerticleIds.confirm();
    if (this.SubVerticleIds._itemsToConfirm.length > 0) {
      this.SubVerticleIds._itemsToConfirm.forEach((ele) => {
        this.selectedSubVerticleIds.push(ele.id);
      });
    }
    this.form.get('SubVerticleIds').setValue(this.selectedSubVerticleIds)
    this.SubVerticleIds.close();
  }
}
