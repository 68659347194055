import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

const api: string = environment.baseUrl;

@Injectable({
    providedIn: 'root'
})
export class ConstantDataService {
    constructor(private http: HttpClient) { }

    findCurrencyService(): Observable<any[]> {
        return this.http.get<any[]>(api + '/api/ConstantData/FindCurrency');
    }

    findUnitsService(): Observable<any[]> {
        return this.http.get<any[]>(api + '/api/ConstantData/FindUnits');
    }

    findItemService(): Observable<any[]> {
        return this.http.get<any[]>(api + '/api/ConstantData/FindItemStatus');
    }

    findOrderStatusService(): Observable<any[]> {
        return this.http.get<any[]>(api + '/api/ConstantData/FindOrderStatus');
    }

    findTableStatusService(): Observable<any[]> {
        return this.http.get<any[]>(api + '/api/ConstantData/FindTableStatus');
    }

    findRolesService(): Observable<any[]> {
        return this.http.get<any[]>(api + '/api/ConstantData/FindRoles');
    }

    findSupplierTypeService(): Observable<any[]> {
        return this.http.get<any[]>(api + '/api/ConstantData/FindSupplierType');
    }

    findPaymentStatusService(): Observable<any[]> {
        return this.http.get<any[]>(api + '/api/ConstantData/FindPaymentStatus');
    }

    findOfferTypesService(): Observable<any[]> {
        return this.http.get<any[]>(api + '/api/ConstantData/FindOfferTypes');
    }

    findProcessService(): Observable<any[]> {
        return this.http.get<any[]>(api + '/api/ConstantData/FindProcess');
    }

    findAccessService(): Observable<any[]> {
        return this.http.get<any[]>(api + '/api/ConstantData/FindAccess');
    }

    findPaymentModeService(): Observable<any[]> {
        return this.http.get<any[]>(api + '/api/ConstantData/FindPaymentMode');
    }

    findDayOfTheWeekService(): Observable<any[]> {
        return this.http.get<any[]>(api + '/api/ConstantData/FindDayOfTheWeek');
    }


    RegisterForRestaurant(formData){
        return this.http.post<any[]>(api + '/api/Accounts/register',formData);
    }
    RegisterForprovider(formData){
        return this.http.post<any[]>(api + '/api/Accounts/register',formData);
    }
    RegisterBroker(formData){
        const headers = new HttpHeaders().append('Content-Disposition', 'multipart/form-data');
        return this.http.post<any[]>(api + '/api/Accounts/RegisterBroker',formData,{ headers: headers });
    }
}

