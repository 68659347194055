import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { MessagesService } from 'src/app/pages/auth/Guided_Visite/messages.service';
import { RestaurantService } from 'src/app/shared/services/api/restaurant.service';
import { MessageService } from 'src/app/shared/services/message-service.service';

@Component({
  selector: 'app-comman-for-all',
  templateUrl: './comman-for-all.component.html',
  styleUrls: ['./comman-for-all.component.scss'],
})
export class CommanForAllComponent implements OnInit {
  GetAllCountry:any=[]
  GetAllTimezone:any=[]
  form3: FormGroup;
  form4: FormGroup;
  @Input() type;
  Viewpassword=false
  selectedType: string;
  isSelected: boolean = true;
  constructor(
    public fb: FormBuilder,
    private  modalCtrl: ModalController,
    private _rsestaurantService: RestaurantService,
    public guidedvisitemessage:MessagesService,
    private messageService: MessageService,
  ) {
    this.form3 = this.fb.group({
      Timezone: ['',[Validators.required]],
      Country: ['',[Validators.required]],
    });
    this.form4 = this.fb.group({
      password: ['',Validators.required],
    });
  }
  selectRegistrationType(event: any) {
    this.selectedType = event.detail.value;
    this.isSelected = false;
  }

  closepopup(){
    this.modalCtrl.dismiss()
  }
  ngOnInit() {
    this.GetAllCountryNames()
  }

  GetAllCountryNames() {
    this._rsestaurantService.GetAllCountryNames().subscribe((result:any) => {
      if (result) {
        this.GetAllCountry=result
        console.log(result);
      }
    });
  }
  onSelectChange(event){
    this.GetTimeZoneOFTheCountryCode(event.target.value)
  }
  GetTimeZoneOFTheCountryCode(code) {
    this._rsestaurantService.GetTimeZoneOFTheCountryCode(code).subscribe((result:any) => {
      if (result) {
        if(result.length==1){
          this.form3.get('Timezone').setValue(result[0].timeZone)
        }
        this.GetAllTimezone=result
        console.log(result);
      }
    });
  }

  Submit(){
    if(this.type=='TimeZone'){
   this.ApplyUpdatetimezone()
    }else if(this.type=='passwordcheck'){
    this.passwordconformation()
    }
  }

  showpassword(){
    this.Viewpassword=!this.Viewpassword
  }

  passwordconformation(){
    if(localStorage.getItem('GuidedVisite')=='true'){
      this.guidedvisitemessage.GuidedVisitemessage('Update','This button allows you to Password Confirmation')
      }else{ 
    if(this.form4.valid){
      console.log(this.form4.value.password)
      this._rsestaurantService.VerifyPassword(this.form4.value.password).subscribe((result)=>{
        console.log(result)
        if(result){
          console.log(result)
          this.modalCtrl.dismiss(true);
          this.messageService.presentToast('Password Confirmation Done')
        }else{
          this.messageService.presentToast('Password Confirmation Faild')
        }
      },error=>{
        if(error){
          this.messageService.presentToast('Password Confirmation Faild')
        }
      })
    }
  }
  }



  ApplyUpdatetimezone() {
    if(localStorage.getItem('GuidedVisite')=='true'){
      this.guidedvisitemessage.GuidedVisitemessage('Update','This button allows you to Update Restaurant TimeZone')
      }else{ 
    if(this.form3.valid){
      const data={
        "timeZone":this.form3.value.Timezone,
        "latitude": 0,
        "longitude": 0
      }
      this._rsestaurantService.UpdateRestaurantTimeZone(data).subscribe((result)=>{
        if(result){
          console.log(result)
          this.modalCtrl.dismiss(true);
        }
      })
    }
  }
  }


  Chooseregister(){
    this.modalCtrl.dismiss(this.selectedType)
  }


  clearpopup() {
    this.modalCtrl.dismiss();
  }
}
