import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-filter-item-status',
  templateUrl: './filter-item-status.component.html',
  styleUrls: ['./filter-item-status.component.scss'],
})
export class FilterItemStatusComponent implements OnInit {
@Input()ScreenType;
  constructor(
    private readonly modalCtrl: ModalController,
  ) { }

  ngOnInit() {
    console.log(this.ScreenType)
  }



  closepopup(){
    this.modalCtrl.dismiss()
  }

}
