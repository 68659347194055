import { Component, Input, OnInit } from '@angular/core';
import { ModalController, PopoverController } from '@ionic/angular';
import { Ingredient } from 'src/app/shared/models/ingredient.model';
import { ProviderProduct } from 'src/app/shared/models/provider-product.model';
import { IngredientService } from 'src/app/shared/services/api/ingredient.service';
import { MessageService } from 'src/app/shared/services/message-service.service';
import { DeleteComponent } from 'src/app/shared/utils/delete/delete.component';
import { IngredientEditComponent } from '../ingredient-edit/ingredient-edit.component';
import { HistoryComponent } from 'src/app/components/history/history.component';
import { IngrediantListComponent } from '../ingrediant-list/ingrediant-list.component';

@Component({
  selector: 'app-ingredient-detail',
  templateUrl: './ingredient-detail.component.html',
  styleUrls: ['./ingredient-detail.component.scss'],
})
export class IngredientDetailComponent implements OnInit {

  @Input() ingredient: any;
  product: ProviderProduct;
  ingrediantList: Ingredient;
  rights: any;
  view = false;
  add = false;
  constructor(
    public modalCtrl: ModalController,
    private popoverCtrl: PopoverController,
    private messageService: MessageService,
    private ingredientService: IngredientService
  ) { }

  ngOnInit() {
    console.log( this.ingredient);
    
    this.rights = JSON.parse(sessionStorage.getItem('permission')) || 0;
      console.log(this.rights + '=============');
      if (this.rights && this.rights.length > 0) {
        // eslint-disable-next-line max-len
        const role = this.rights.filter((ele: any) => ele.rights === 'Ingredients');
        console.log(this.rights)
        if (role.length > 0) {
          if (role[0].access.length > 0) {
            if (role[0].access.length > 1) {
              const isAll = role[0].access.filter((el: any) => el === 'View' || el === 'Update' || el === 'Create' || el === 'Delete');
              if (isAll.length > 0) {
                this.add = true;
                this.view = true;
              }
            } else {
              const isview = role[0].access.filter((el: any) => el === 'View');
              if (isview.length > 0) {
                this.add = false;
                this.view = true;
              }
            }
          }
        }
      }
      this.getIngredientById();
   }
   getIngredientById() {
    this.ingredientService.getIngredientById(this.ingredient.id ? this.ingredient.id : this.ingredient.ingredientId).subscribe(res => {
      this.ingrediantList = res;
    })
  }
  async openEditIngredient() {
    const modal = await this.modalCtrl.create({
      component: IngredientEditComponent,
      componentProps: {
        ingredient: this.ingrediantList
      }
    });

     await modal.present();
     const { data } = await modal.onDidDismiss();
     if (data) {
       this.getIngredientById();

     }
  }

  async deleteIngredient() {
    const popover = await this.popoverCtrl.create({
      component: DeleteComponent,
      translucent: true,
      componentProps: {
        name: this.ingredient.ingredientName
      }
    });
    await popover.present();

    const { data } = await popover.onDidDismiss();
    if (data) {
      this.delete();
    }
  }

  delete() {
    this.ingredientService.deleteIngredientService(this.ingredient.id).subscribe(response => {
      if (response.status === 'success') {
        this.modalCtrl.dismiss(true);
        this.messageService.presentToast(response.message);
      }
    });
  }
  

  async QuantityHistory(){
    const modal = await this.modalCtrl.create({
      component: HistoryComponent,
      componentProps: {data:{ ingredient: this.ingrediantList,screenType:'QuantityHistory'} }

    });
     await modal.present();
     const { data } = await modal.onDidDismiss();
     if (data) {
     }
  }

  async QuantityexpiryDate(){
    const modal = await this.modalCtrl.create({
      component: HistoryComponent,
      componentProps: {data:{ ingredient: this.ingrediantList,screenType:'Quantityexpiry'} }
    });
     await modal.present();
     const { data } = await modal.onDidDismiss();
     if (data) {
     }
  }

  async privateingrediantlist(){
    const modal = await this.modalCtrl.create({
      component: IngrediantListComponent,
      componentProps: {
        ingredient: this.ingrediantList
      }
    });
     await modal.present();
     const { data } = await modal.onDidDismiss();
     if (data) {
     }
  }

}
