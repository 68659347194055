import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-add-new-tag',
  templateUrl: './add-new-tag.component.html',
  styleUrls: ['./add-new-tag.component.scss'],
})
export class AddNewTagComponent implements OnInit {
  selectedradioval=null;
  selectednumeralval=null;
  tagform: FormGroup;
  viewtagmodel :any= {};
  showfreefeild!: boolean;
  tagViewModels:any = [];
  showcheckbox!: boolean;
  dropdownValues = [1, 2, 3, 4, 5]
  tags: any;
  tagType: any
  selectedvalue: any;
  value!: number;
  i = 1;
  dropdownValue :any= []
  constructor(
    private formBuilder: FormBuilder,
    public modalCtrl: ModalController,
  ) { 
    this.tagform = this.formBuilder.group({
      TagViewModels: [[]],
      tagType: [''],
      title: [''],
      tagdropdown: [''],
      ischecked: [''],
      numeralType: [''],
      freefeildTitle: ['', Validators.pattern('^[a-zA-Z \-\']+')],
      freefeildnumber: [''],
      fieldName: [''],
      fieldvalue: [''],
      dropdownValue: [''],
      freefeildvalue: [''],
      dropdowntype: [''],
      dropdown: [''],
      fieldType:[''],
      Mandatory:[false],
      Adddropdownvalue: this.formBuilder.array([
        this.createBody()
      ])
    });
  }

  ngOnInit(): void {
  }


  createBody(): FormGroup {
    return this.formBuilder.group({
      dropdownvalue: ['']
    })
  }
  addcreateBody() {
    this.getfileBody().push(this.createBody())
  }

  removeConditionalBody(i: number) {
    this.getfileBody().removeAt(i);
  }
  getfileBody() {
    return this.tagform.get('Adddropdownvalue') as FormArray;
  }

  close() {
    this.modalCtrl.dismiss(true)
  }
  checkChanges() {
    this.tagform.get('numeralType')?.valueChanges.subscribe(val => {
      this.selectednumeralval = val;
    });
  }
  getdropdown(i:number) {
    this.selectedvalue = i;
  }
  onChanges() {
    this.tagform.get('tagType')?.valueChanges.subscribe(val => {
      this.selectedradioval = val;
    });
  }


  onSubmit(){
    if(this.tagform.value.tagType== 'Checkbox'){
      this.tagform.get('fieldName').setValue(this.tagform.value.title)
      this.tagform.get('fieldType').setValue('Bool')

    }else if(this.tagform.value.tagType== 'Freefeild'){
      this.tagform.get('fieldName').setValue(this.tagform.value.title)
      this.tagform.get('fieldType').setValue('Number')
    }else{
      this.tagform.get('fieldName').setValue(this.tagform.value.title)
      this.tagform.get('fieldType').setValue('Text')
    }

    this.modalCtrl.dismiss(this.tagform.value)
  }
}
