import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
    providedIn: 'root'
})
export class MessageService {
    constructor(public toastController: ToastController) { }

    async presentToast(messageTxt: string) {
        const toast = await this.toastController.create({
            message: messageTxt,
            duration: 2500
        });
        toast.present();
    }
}