import { DatePipe } from '@angular/common';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import Chart, { ChartType } from 'chart.js';
import { RestaurantService } from 'src/app/shared/services/api/restaurant.service';
import { Level3ChartShowComponent } from '../level3-chart-show/level3-chart-show.component';
import { Label, MultiDataSet } from 'ng2-charts';
import { ReportService } from 'src/app/shared/services/api/report.service';
import { E } from '@angular/cdk/keycodes';
import { MessageService } from 'src/app/shared/services/message-service.service';
import { saveAs } from 'file-saver';
import { MessagesService } from 'src/app/pages/auth/Guided_Visite/messages.service';

@Component({
  selector: 'app-level2-chart-show',
  templateUrl: './level2-chart-show.component.html',
  styleUrls: ['./level2-chart-show.component.scss'],
})
export class Level2ChartShowComponent implements OnInit {
  ItemsGraphReportdatastoring: any;
  ExportReportForGlobalReportdatastoring: any;
  GenerateReportForDeliveryItemsdatastoring: any;
  generateCustomerReportdatastoring: any;
  GenerateOrderReportdatastoring: any;
  SalesReportdatastoring: any;
  tablePaymentReport: any;
  empoyeePaymentReport: any;
  isEmpoyleePayment = false;
  datetype: any;
  charttype = '';
  date: any;
  @Input() data: any;
  chartResult: any;
  chart: Chart;
  startDate: any;
  endDate: any;
  public chartType = 'pie';
  public chartData: any;
  restaurantIds;
  // Doughnut chart
  public doughnutChartLabels: Label[] = [];
  public doughnutChartData: MultiDataSet = [];
  public donutColors = [
    {
      backgroundColor: [
        'rgba(0,64,64, 1)',
        'rgba(118, 183, 172, 1)',
        'rgba(0, 148, 97, 1)',
        'rgba(0, 128, 128, 1)',
        'rgba(129, 199, 111, 1)',
      ],
    },
  ];
  public doughnutChartType: ChartType = 'doughnut';
  public paymentDoughnutChartLabels: Label[] = [];
  public paymentDoughnutChartData: MultiDataSet = [];
  public paymentDoughnutChartType: ChartType = 'doughnut';
  public pieChartType: ChartType = 'doughnut';
  public paymentDoughnutColors = [
    {
      backgroundColor: [
        'rgba(135,206,250,1)',
        'rgba(0,0,255,1)',
        'rgba(118,169,197,1)',
        'rgba(0,200,200, 1)',
        'rgba(0,128,192, 1)',
        'rgba(0,64,128, 1)',
      ],
    },
  ];
  private tableDoughnutChartLabels: Label[] = [];
  private tableDoughnutChartData: MultiDataSet = [];
  private tableDoughnutChartType: ChartType = 'doughnut';
  private tableDoughnutColors = [
    {
      backgroundColor: [
        'rgba(135,206,250,1)',
        'rgba(0,0,255,1)',
        'rgba(118,169,197,1)',
        'rgba(0,200,200, 1)',
        'rgba(0,128,192, 1)',
        'rgba(0,64,128, 1)',
      ],
    },
  ];
  empolyeeReport = 'Employee';
  spinnerstatus: boolean = false;
  PageNumber=1  
  PageSize=10
  CustomerLastVisitedOrders: any;
  CustomerOnlineOrders: any;
  CustomerOrders: any;

  constructor(
    private readonly modalCtrl: ModalController,
    private restaurantService: RestaurantService,
    private datePipe: DatePipe,
    private reportService: ReportService,
    private messageService: MessageService,
    public guidedvisitemessage:MessagesService
  ) {}

  ngOnInit() {
    if(this.data){
      console.log(this.data); 
      this.charttype = this.data.charttype;
      this.datetype = this.data.datetype;
      if (this.charttype == 'ItemsReport') {
        this.startDate=this.data?.data[0].StartDate;
        this.endDate=this.data?.data[0].EndDate;
        this.restaurantIds=this.data.data[0].listOfItems[0].restaurantId;
        this.leveltwochartforline(this.data);
      } else if (this.charttype == 'SalesReport') {
        this.startDate=this.data?.data?.startDate;
        this.endDate=this.data?.data?.endDate;
        this.restaurantIds=this.data.data[0].restaurantId;
        this.leveltwochartforline(this.data);
      }else if(this.charttype=='money_spent'||this.charttype=='delivered_items'){
        this.leveltwochartforline(this.data);
      }else if(this.charttype=='Lastvisit'){
        this.getLastvisit(this.PageNumber,this.PageSize)
        
      }else if(this.charttype=='TotalVisits'){
        this.getTotalVisits(this.PageNumber,this.PageSize)
        
      }else if(this.charttype=='OnlineOrders'){
        this.getOnlineOrders(this.PageNumber,this.PageSize)
      }else if(this.charttype=='TotalVisitsbsedondate'){
        this.CustomerLastVisitedOrders=this.data.data
      }else if(this.charttype=='OnlineOrdersbsedondate'){
        this.CustomerLastVisitedOrders=this.data.data
      }
    }
 
  }

  getvalidorderitems(){
    this.restaurantService.FindCustomerItems(this.data.data. clientdetails[0]?.clientName).subscribe((result)=>{
      if(result){

      }
    })
    this.restaurantService.FindCustomerItems(this.data.data. clientdetails[0]?.clientName).subscribe((result)=>{
      if(result){

      }
    })
  }


  exportitemsReport(){
    if(localStorage.getItem('GuidedVisite')=='true'){
      this.guidedvisitemessage.GuidedVisitemessage('Export','This button allows you to Export Report')
      }else{ 
    this.restaurantService.ItemsCategoryReport(this.data.datetype, this.date, this.restaurantIds,this.data.data[0].itemId,this.data.status,this.data.DeliveryProvidersId).subscribe((result)=>{
      if(result){
        console.log(result)
        saveAs(result, `Itemsreport.xlsx`);
        this.messageService.presentToast('Export Repot Done')
      }
    })
  }
  }
  MoneySpentReportReport(){
    if(localStorage.getItem('GuidedVisite')=='true'){
      this.guidedvisitemessage.GuidedVisitemessage('Export','This button allows you to Export Report')
      }else{ 
    this.restaurantService.MoneySpentReportReport(this.chartResult).subscribe((result)=>{
      if(result){
        console.log(result)
        saveAs(result, `MoneySpentReport.xlsx`);
        this.messageService.presentToast('Export Repot Done')
      }
    })
  }
  }
  DeliveryReport(){
    if(localStorage.getItem('GuidedVisite')=='true'){
      this.guidedvisitemessage.GuidedVisitemessage('Export','This button allows you to Export Report')
      }else{ 
    this.restaurantService.DeliveryReport(this.chartResult).subscribe((result)=>{
      if(result){
        console.log(result)
        saveAs(result, `DeliveryReport.xlsx`);
        this.messageService.presentToast('Export Repot Done')
      }
    })
  }
  }

  exportmoney_spentdelivered_itemsReport(){
    if(this.charttype=='money_spent'){
    this.MoneySpentReportReport()
    }else{
      this.DeliveryReport()
    }
  }
  closepopup() {
    this.modalCtrl.dismiss();
  }

  leveltwochartforline(data) {
    console.log(data)
    // year
    if (data.datetype === 'year'||data.datetype === "Yearly") {
      if (data.charttype == 'ItemsReport') {
        this.date=data.data[0].listOfItems[0].month+' '+data.data[0].listOfItems[0].year
        this.restaurantService .getlevel2chartforline('Yearly', this.date, this.restaurantIds,data.data[0].itemId,data.status,data.DeliveryProvidersId).subscribe((result) => {
            if (result) {
              this.chartResult = result;
              this.linechartlevel2dataformatingforitem(result);
            }
          });
      } else if (data.charttype == 'SalesReport') {
        this.date=data.data[0].month+' '+data.data[0].year
        this.empolyeeEvent(this.empolyeeReport, 'load');
      }else if(data.charttype == 'money_spent'||data.charttype == 'delivered_items'){
        this.startDate=this.datePipe.transform(this.data.data.startDate, 'yyyy-MM-dd')
        this.endDate=this.datePipe.transform(this.data.data.endDate, 'yyyy-MM-dd')
        var obj = {
          startDate: this.datePipe.transform(this.data.data.startDate, 'yyyy-MM-dd'),
          endDate:this.datePipe.transform(this.data.data.endDate, 'yyyy-MM-dd'),
          xDisplayType:3,
          RestaurantIds:this.data.data.RestaurantIds,
          ChartType:1,
          CategoryOfProductId:this.data.data[0].id,
          IsDelivery:this.data.data.resMarketPlace === 'money_spent'?false:true,
          Levels:2
        };
        this.reportService.GenerateMoneySpendandDeliveryItemReportleve2(obj).subscribe((result)=>{
          if(result){
            this.chartResult = result;
            this.linechartlevel2dataformatingformoney_spent(result,this.data.data.resMarketPlace === 'money_spent'?false:true)
          }
        })
      }
    } else if (data.datetype === 'month'||data.datetype === 'Monthly') {
      if (data.charttype == 'ItemsReport') {
        this.date=data.data[0].listOfItems[0].date
        this.restaurantService .getlevel2chartforline('Monthly', this.date, this.restaurantIds,data.data[0].itemId,data.status,data.DeliveryProvidersId)
          .subscribe((result) => {
            if (result) {
              this.chartResult = result;
              this.linechartlevel2dataformatingforitem(result);
            }
          });
      } else if (data.charttype == 'SalesReport') {
        this.date=data.data[0].orderedDate
        this.empolyeeEvent(this.empolyeeReport, 'load');
      }else if(data.charttype == 'money_spent'||data.charttype == 'delivered_items'){
        this.startDate=this.datePipe.transform(this.data.data.startDate, 'yyyy-MM-dd')
        this.endDate=this.datePipe.transform(this.data.data.endDate, 'yyyy-MM-dd')
        var obj = {
          startDate: this.datePipe.transform(this.data.data.startDate, 'yyyy-MM-dd'),
          endDate:this.datePipe.transform(this.data.data.endDate, 'yyyy-MM-dd'),
          xDisplayType:2,
          RestaurantIds:this.data.data.RestaurantIds,
          CategoryOfProductId:this.data.data[0].id,
          ChartType:1,
          IsDelivery:this.data.data.resMarketPlace === 'money_spent'?false:true,
          Levels:2
        };
        this.reportService.GenerateMoneySpendandDeliveryItemReportleve2(obj).subscribe((result)=>{
          if(result){
            this.chartResult = result;
            this.linechartlevel2dataformatingformoney_spent(result,this.data.data.resMarketPlace === 'money_spent'?false:true)
          }
        })
      }
    } else if (data.datetype === 'day'||data.datetype === 'dayly'||data.datetype === 'Daily') {
      if (data.charttype == 'ItemsReport') {
        this.date=data.data[0].listOfItems[0].date+' '+data.data[0].listOfItems[0].hours
        this.restaurantService.getlevel2chartforline('dayly', this.date , this.restaurantIds,data.data[0].itemId,data.status,data.DeliveryProvidersId)
          .subscribe((result) => {
            if (result) {
              this.chartResult = result;
              this.linechartlevel2dataformatingforitem(result);
            }
          });
      } else if (data.charttype == 'SalesReport') {
        this.date=data.data[0].orderedDate+' '+data.data[0].hours
        this.empolyeeEvent(this.empolyeeReport, 'load');
      }else if(data.charttype == 'money_spent'||data.charttype == 'delivered_items'){
        this.startDate=this.datePipe.transform(this.data.data.startDate, 'yyyy-MM-dd')
        this.endDate=this.datePipe.transform(this.data.data.endDate, 'yyyy-MM-dd')
        var object = {
          startDate: this.datePipe.transform(this.data.data.startDate, 'yyyy-MM-dd'),
          endDate:this.datePipe.transform(this.data.data.endDate, 'yyyy-MM-dd'),
          xDisplayType:1,
          CategoryOfProductId:this.data.data[0].id,
          RestaurantIds:this.data.data.RestaurantIds,
          ChartType:1,
          IsDelivery:this.data.data.resMarketPlace === 'money_spent'?false:true,
          Levels:2
        };
        this.reportService.GenerateMoneySpendandDeliveryItemReportleve2(object).subscribe((result)=>{
          if(result){
            this.chartResult = result;
            this.linechartlevel2dataformatingformoney_spent(result,this.data.data.resMarketPlace === 'money_spent'?false:true)
          }
        })
      }
    }
  }

  linechartlevel2dataformatingforitem(value: any) {
    var labels = [];
    var data = [];
    value.categoryItems.forEach((element) => {
      data.push(element.itemCount);
      labels.push(element.category);
    });
    this.chartData = {
      ChartType :'doughnut',
      labels: labels,
      datasets: [
        {
          data: data,
          backgroundColor: [
            'rgba(0,64,64, 1)',
            'rgba(118, 183, 172, 1)',
            'rgba(0, 148, 97, 1)',
            'rgba(0, 128, 128, 1)',
            'rgba(129, 199, 111, 1)',
          ],
        },
      ],
    };
  }

  linechartlevel2dataformatingformoney_spent(value: any,money_spent) {
    console.log(value)
    console.log(money_spent)
    var labels = [];
    var data = [];
    if(money_spent==true){
      value.deliveryitemlevel2.forEach((element) => {
        data.push(element.quantity);
        labels.push(element.productName);
      });
    }else{
      value.paymentsLevel2.forEach((element) => {
        data.push(element.amount);
        labels.push(element.productName);
      });
    }
    this.chartData = {
      ChartType :'doughnut',
      labels: labels,
      datasets: [
        {
          data: data,
          backgroundColor: [
            'rgba(0,64,64, 1)',
            'rgba(118, 183, 172, 1)',
            'rgba(0, 148, 97, 1)',
            'rgba(0, 128, 128, 1)',
            'rgba(129, 199, 111, 1)',
          ],
        },
      ],
    };
  }



 






   // ItemsReport
   levelthrechartForitem(event: any): void {
    console.log(event.active[0]);
    if (event.active && event.active.length > 0) {
      var chartElement = event.active[0];
      var datasetIndex = chartElement._datasetIndex;
      var dataIndex = chartElement._index;
      var label = this.chartData.labels[dataIndex];
      var value = this.chartData.datasets[datasetIndex].data[dataIndex];
      console.log(`Clicked on ${label}: ${value}`);
    }
    this.chartResult.categoryItems.forEach((element) => {
      if (element.category == label) {
        console.log(element.categoryId);
        this.GetItemsBasedOncategoryId(element.categoryId);
      }
    });
  }
  GetItemsBasedOncategoryId(categoryId) {
    this.restaurantService.GetSoldItems(this.startDate,this.endDate, categoryId,this.restaurantIds ).subscribe((result:any) => {
        if (result) {
          console.log(result);
          result.restaurantIds=this.restaurantIds
          this.levelthrechart(result,'ItemsReport');
        }
      });
  }

    // SalesReport 
    empolyeeEvent(ev: any, type?: string) {
      const value = type !== 'load' ? ev.detail.value : ev;
      this.empolyeeReport = value;
      if (this.empolyeeReport === 'Employee') {
        const obj = {
          XAxis: this.date,
          XAxisType:
          this.data.datetype == 'year' ? 'Yearly'
         : this.data.datetype == 'month'? 'Monthly': 'dayly',
          isEmployee: true,
          paymentChartType: 1,
          restaurantIds: this.restaurantIds,
        };
        this.spinnerstatus = true;
        this.reportService.generatePaymentReport(obj,this.data.status).subscribe(
          (res) => {
            this.spinnerstatus = false;
            this.SalesReportdatastoring = res;
            this.tablePaymentReport = res;
            if (this.empolyeeReport === 'Employee') {
              const filterArray1 = [];
              this.doughnutChartLabels = [];
              this.doughnutChartData = [];
              if (
                this.tablePaymentReport &&
                this.tablePaymentReport[0].employeePayments.length > 0
              ) {
                this.tablePaymentReport[0].employeePayments.forEach((element) => {
                  this.doughnutChartLabels.push(
                    element.firstName + ' ' + element.lastName
                  );
                  filterArray1.push(element.amount);
                });
                this.doughnutChartData = filterArray1;
              }
            } else {
              const filterArray2 = [];
              this.tableDoughnutChartLabels = [];
              this.tableDoughnutChartData = [];
              if (
                this.tablePaymentReport &&
                this.tablePaymentReport[0].tablePayments.length > 0
              ) {
                this.tablePaymentReport[0].tablePayments.forEach((element) => {
                  this.tableDoughnutChartLabels.push(element.paymentTypeName);
                  filterArray2.push(element.amount);
                });
                this.tableDoughnutChartData = filterArray2;
              }
            }
          },
          (err) => {
            this.spinnerstatus = false;
            console.log(err + 'employee');
          }
        );
      } else if (this.empolyeeReport === 'payment') {
        const obj = {
          XAxis: this.date,
          XAxisType:
            this.data.datetype == 'year'
              ? 'Yearly'
              : this.data.datetype == 'month'
              ? 'Monthly'
              : 'dayly',
          isEmployee: false,
          paymentChartType: 1,
          restaurantIds: this.restaurantIds,
        };
        this.spinnerstatus = true;
        this.reportService.generatePaymentReport(obj,this.data.status).subscribe(
          (res) => {
            this.spinnerstatus = false;
            this.SalesReportdatastoring = res;
            this.empoyeePaymentReport = res;
            this.isEmpoyleePayment = false;
            const filterArray = [];
            this.paymentDoughnutChartLabels = [];
            this.paymentDoughnutChartData = [];
            if (
              this.empoyeePaymentReport &&
              this.empoyeePaymentReport[0].modeOfPayments.length
            ) {
              this.empoyeePaymentReport[0].modeOfPayments.forEach((element) => {
                this.paymentDoughnutChartLabels.push(element.paymentTypeName);
                filterArray.push(element.amount);
              });
              this.paymentDoughnutChartData = filterArray;
            }
          },
          (err) => {
            this.spinnerstatus = false;
          }
        );
      }
    }



    // money_spent 
    levelthrechartFormoney_spent(event: any): void {
      console.log(event.active[0]);
      if (event.active && event.active.length > 0) {
        var chartElement = event.active[0];
        var datasetIndex = chartElement._datasetIndex;
        var dataIndex = chartElement._index;
        var label = this.chartData.labels[dataIndex];
        var value = this.chartData.datasets[datasetIndex].data[dataIndex];
        console.log(`Clicked on ${label}: ${value}`);
        this.chartResult.paymentsLevel2.forEach(element => {
          if(element.productName==label){
            this.GenerateMoneySpendandDeliveryItemReportlevel3(element.productId)
    
          }
        });
      }
    }

    GenerateMoneySpendandDeliveryItemReportlevel3(productId){
      console.log(productId)
      var object = {
        startDate: this.datePipe.transform(this.startDate, 'yyyy-MM-dd'),
        endDate:this.datePipe.transform(this.endDate, 'yyyy-MM-dd'),
        xDisplayType:3,
        RestaurantIds:this.data.data.RestaurantIds,
        ChartType:1,
        IsDelivery:this.data.data.resMarketPlace === 'money_spent'?false:true,
        Levels:3,
        productId:productId
      };
      this.reportService.GenerateMoneySpendandDeliveryItemReportlevel3(object).subscribe((result)=>{
        if(result){
          if(this.data.data.resMarketPlace === 'money_spent'){
            this.levelthrechart(result,'money_spent')
          }else{
            this.levelthrechart(result,'delivered_items')

          }
        }
      })
    }


    getStartAndEndDateForMonth(monthStr: string, year: number): { startDate: Date, endDate: Date } {
      const monthMap: { [key: string]: number } = {
        'January': 1,
        'February': 2,
        'March': 3,
        'April': 4,
        'May': 5,
        'June': 6,
        'July': 7,
        'August': 8,
        'September': 9,
        'October': 10,
        'November': 11,
        'December': 12
      };
      const month = monthMap[monthStr];
      const startDate = new Date(year, month - 1, 1)
      const endDate = new Date(year, month, 0);
      return {
        startDate,
        endDate
      };
    }
    getYearFromDate(dateString: string): number {
      const date = new Date(dateString);
      return date.getFullYear();
    }

    // delivered_items 
    levelthrechartFordelivered_items(event: any): void {
      console.log(event.active[0]);
      if (event.active && event.active.length > 0) {
        var chartElement = event.active[0];
        var datasetIndex = chartElement._datasetIndex;
        var dataIndex = chartElement._index;
        var label = this.chartData.labels[dataIndex];
        var value = this.chartData.datasets[datasetIndex].data[dataIndex];
        console.log(`Clicked on ${label}: ${value}`);
        this.chartResult.deliveryitemlevel2.forEach(element => {
          if(element.productName==label){
            this.GenerateMoneySpendandDeliveryItemReportlevel3(element.productId)
          }
        });
      }
    }

    getLastvisit(PageNumber,PageSize){
      this.reportService.FindCustomerLastVisitedOrders(this.data?.data?.customerId).subscribe((result:any)=>{
        if(result){
          console.log(result)
          this.CustomerLastVisitedOrders=result
          this.getvalidorderitems()
        }
      })

    }

    getTotalVisits(PageNumber,PageSize){
            this.reportService.FindCustomerOnlineOrders(this.data?.data?.customerId,PageNumber,PageSize).subscribe((result:any)=>{
        if(result){
          this.CustomerOnlineOrders=result.data
          console.log(result)
          this.getvalidorderitems()
        }
      })
    }

    getOnlineOrders(PageNumber,PageSize){
            this.reportService.FindCustomerOrders(this.data?.data?.customerId,PageNumber,PageSize).subscribe((result:any)=>{
        if(result){
          this.CustomerOrders=result.data
          console.log(result)
          this.getvalidorderitems()
        }
      })
    }








  // levelthrechart 
  async levelthrechart(value: any,type) {
    const popover = await this.modalCtrl.create({
      component: Level3ChartShowComponent,
      componentProps: {
        data: {
          data: value,
          charttype: type,
          startDate: this.startDate,
          endDate: this.endDate,
        },
      },
    });
    await popover.present();
    const { data } = await popover.onDidDismiss();
    if (data) {
      console.log(data);
    }
  }

  showTotalVisitsbasedondate(data){
  this.leveltwochart(data,'TotalVisitsbsedondate')
  }
  
  showOnlineOrdersbasedondate(data){
  this.leveltwochart(data,'OnlineOrdersbsedondate')
  }

      // leveltwochart 
      async leveltwochart(value: any,type) {
        const popover = await this.modalCtrl.create({
          component: Level2ChartShowComponent,
          componentProps: {
            data: {
              data: value,
              charttype: type,
            },
          },
        });
        await popover.present();
        const { data } = await popover.onDidDismiss();
        if (data) {
          console.log(data);
        }
      }
}


