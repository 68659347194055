import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { CategoryService } from 'src/app/shared/services/api/category.service';
import { LeanrestroGalleryFilterCretionComponent } from '../leanrestro-gallery-filter-cretion/leanrestro-gallery-filter-cretion.component';

@Component({
  selector: 'app-leanresto-gallery',
  templateUrl: './leanresto-gallery.component.html',
  styleUrls: ['./leanresto-gallery.component.scss'],
})
export class LeanrestoGalleryComponent implements OnInit {
  @Input()data
  imagecount=50
  imageLidt=[]
  constructor(
    private readonly modalCtrl: ModalController,
    private categoryService: CategoryService,
  ) { }

  ngOnInit() {
    console.log(this.data.name)
    this.categoryService.GetAllImageLibraries().subscribe((result)=>{
      if(result){
        this.imageLidt=result
      }
      console.log(result)
    })
  }

  selactedimage(value){
    this.modalCtrl.dismiss(value)
  }

  popupclear(){
    this.modalCtrl.dismiss()
  }





  async CreateAndFilterModal(value) {
    console.log('log')
    const popover = await this.modalCtrl.create({
      component: LeanrestroGalleryFilterCretionComponent,
      cssClass: 'scrollable',
      componentProps: { ScreenType: value }
    });
    await popover.present();
    const { data } = await popover.onDidDismiss();
    if(data){
      this.imageLidt=data
    }
  }


  nameformat(value){
    return value.substring(0,10)
  }
}




