import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { F } from '@angular/cdk/keycodes';
import { PlanningService } from 'src/app/shared/services/api/planning.service';

@Component({
  selector: 'app-google-auth-redirect',
  templateUrl: './google-auth-redirect.component.html',
  styleUrls: ['./google-auth-redirect.component.scss']
})
export class GoogleAuthRedirectComponent implements OnInit {
  user: any; // Initialize with null
  accessToken:any
  microsoftaccessToken:any
  resposncesstatus:any

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private planningService: PlanningService,
  ) { }

  ngOnInit(): void {
    sessionStorage.setItem('screentypeMobile','true') 
    this.route.queryParams.subscribe(params => {
      console.log(params)
      if(params?.['code']) {
       this.planningService.SynchronizeGoogleAccount(params?.['code']).subscribe((result:any)=>{
        if(result){
          // this.planningService.ExportPlanningIntoGoogleCalendar(result.accessToken).subscribe((result)=>{
          // })
          sessionStorage.setItem('screentypeMobile','false') 
          console.log(result)
          this.resposncesstatus=true
          const scopes: string = params['scope'];
          if (scopes) {
            const scopesArray: string[] = scopes.split(' ');
               if(scopesArray.includes('https://www.googleapis.com/auth/calendar.events')){
                sessionStorage.setItem('screentypeMobile','true') 
                setTimeout(() => {
                  this.router.navigate(['/planning'])
                }, 10);
              }
          }
        }
       },error=>{
        console.log(error)
        if(error.status==200){
          this.resposncesstatus=true
          const scopes: string = params['scope'];
          if (scopes) {
            const scopesArray: string[] = scopes.split(' ');
               if(scopesArray.includes('https://www.googleapis.com/auth/calendar.events')){
                sessionStorage.setItem('screentypeMobile','true') 
                setTimeout(() => {
                  this.router.navigate(['/planning'])
                }, 10);
              }
          }
        }else{
        this.resposncesstatus=false
        }
       })

      } 
    });
  }



}
