import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { MessagesService } from 'src/app/pages/auth/Guided_Visite/messages.service';
import { BankAccountService } from 'src/app/shared/services/api/bank-account.service';
import { CategoryService } from 'src/app/shared/services/api/category.service';
import { MessageService } from 'src/app/shared/services/message-service.service';
import { CardInfoComponent } from '../../card-info/card-info.component';

@Component({
  selector: 'app-cart-page2',
  templateUrl: './cart-page2.component.html',
  styleUrls: ['./cart-page2.component.scss'],
})
export class CartPage2Component implements OnInit {
@Input()data: any;
cardActive: any;

  constructor(
    private readonly modalCtrl: ModalController,
    private formBuilder: FormBuilder,
    private datePipe: DatePipe,
    private categoryService: CategoryService,
    public guidedvisitemessage:MessagesService,
    private bankAccountService: BankAccountService,
    private messageService: MessageService,
  ) { }

  ngOnInit() {
    console.log(this.data)
    this.isCardActive()
  }

  getProviderTotal(data: any): number {
    return parseFloat((data.totalPrice + data.taxAmount + 100 - data.offerPrice).toFixed(2));
  }

  getGrandTotal(): number {
    return parseFloat(this.data.reduce((sum, provider) => sum + this.getProviderTotal(provider), 0).toFixed(2));
  }
  isCardActive() {
    this.bankAccountService.isActiveCardAvailable().subscribe(data => {
      console.log(data)
      this.cardActive = data;
    })
  }

  proceedToPayment() {
    console.log('Proceeding to payment...');
    // Add payment logic here

    let obj1=[]
    this.data.forEach(item => {
      let obj = {
        "providerId": item.providerId,
        "providerUserId": item.providerUserId,
        "providerName": item.providerName,
        "providerProfile": item.productProfile,
        "dealId": item.dealId,
        "amount": item.total,
        "currenyId": item.currencyId,
        "currencyName": item.currencyName
      }
      obj1.push(obj)
    });
    if(sessionStorage.getItem('GuidedVisite')=='true'){
      this.guidedvisitemessage.GuidedVisitemessage('Provider Payment','This button allows you to Provider Payment ')
      }else{
        if (this.cardActive === true) {
          let obj = {
            "restaurantId": sessionStorage.getItem('resturantId'),
            "restaurantName": sessionStorage.getItem('resturantName'),
            "restaurantProfile": sessionStorage.getItem('resturantProfile'),
            "providers": obj1
          }
          this.bankAccountService.cartPay(obj).subscribe((res) => {
            this.messageService.presentToast(res.message);
            this.modalCtrl.dismiss(true)
          })
        }else{
          this.cardInfo();
        }
  }
}
  async cardInfo() {
    const modal = await this.modalCtrl.create({
      component: CardInfoComponent,
    });
    await modal.present();

    const { data } = await modal.onDidDismiss();
    if (data === true) {
      alert('test')
    }
  }
  
  closepopup(){
    this.modalCtrl.dismiss()
  }

}
