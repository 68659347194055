import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-filter-item-status',
  templateUrl: './filter-item-status.component.html',
  styleUrls: ['./filter-item-status.component.scss'],
})
export class FilterItemStatusComponent implements OnInit {
@Input()ScreenType;
inprocress_R: FormGroup; 
History_R: FormGroup; 
  constructor(
    private readonly modalCtrl: ModalController,
    private formBuilder: FormBuilder,
    private datePipe: DatePipe,
  ) { 

    this.inprocress_R = this.formBuilder.group({
      Search: [null],
      startDate: [null],
      endDate: [null],
      status: [null],
      Delivarydays:[null],
      Providers:[null]
    });
    this.History_R = this.formBuilder.group({
      Search: [null],
      startDate: [null],
      endDate: [null],
      status: [null],
      Providers:[null]

    });
  }

  ngOnInit() {
    console.log(this.ScreenType)
  }

  onSubmit(){
    
  }



  closepopup(){
    this.modalCtrl.dismiss()
  }

}
