import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { RestaurantService } from 'src/app/shared/services/api/restaurant.service';
import { MessageService } from 'src/app/shared/services/message-service.service';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-level4-chart-show',
  templateUrl: './level4-chart-show.component.html',
  styleUrls: ['./level4-chart-show.component.scss'],
})
export class Level4ChartShowComponent implements OnInit {
  charttype=''
  @Input() data: any;
  chartdata:any;
  chart: Chart;
  startDate:any;
  endDate:any;
  itemOrderDetails:any
  CustomerOrderDetails:any
  constructor(
    private readonly modalCtrl: ModalController,
    private restaurantService: RestaurantService,
    private datePipe: DatePipe,
    private messageService: MessageService,
  ) { }

  ngOnInit() {
    console.log(this.data)
   if(this.data.charttype=='CustomersReport'){
      this.charttype=this.data.charttype
      this.startDate=this.data?.data?.startDate
      this.endDate=this.data?.data?.endDate
      this.GetCustomerOrderDetails(this.data.data.id)
    }else if(this.data.charttype=='ItemsReport'){
      this.charttype=this.data.charttype
      this.GetSoldItemDetail(this.data.data.itemId)

    }
  }

  closepopup(){
    this.modalCtrl.dismiss()
  }
  exportitemsReport(){
    this.restaurantService.SoldItemDetailsReport(this.data.startDate,this.data.endDate,this.data.data.itemId,this.data.data.restaurantId).subscribe((result)=>{
      if(result){
        console.log(result)
        saveAs(result, `Itemsreport.xlsx`);
        this.messageService.presentToast('Export Repot Done')
      }
    })
  }
  exporCustomersReport(){
    this.restaurantService.CustomerCrmReport(this.CustomerOrderDetails).subscribe((result)=>{
      if(result){
        console.log(result)
        saveAs(result, `Itemsreport.xlsx`);
        this.messageService.presentToast('Export Repot Done')
      }
    })
  }

  GetSoldItemDetail(id){
    this.restaurantService.GetSoldItemDetail(this.data.startDate,this.data.endDate,id,localStorage.getItem('resturantId')).subscribe((result)=>{
      if(result){
        this.itemOrderDetails=result
      }
    })
  }

  GetCustomerOrderDetails(id){
    this.restaurantService.GetCustomerOrderDetails(this.data.startDate,this.data.endDate,id,localStorage.getItem('resturantId')).subscribe((result)=>{
      if(result){
        this.CustomerOrderDetails=result
      }
    })
  }

}
