import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

const api: string = environment.baseUrl;

@Injectable({
    providedIn: 'root'
})
export class PlanningService {
    constructor(private http: HttpClient) { }

    getPlanningService(startDateTime: string, endDateTime: string, userId: string): Observable<any[]> {
        const params = new HttpParams()
            .append('startDateTime', startDateTime)
            .append('endDateTime', endDateTime)
            .append('userId', userId);
            console.log(startDateTime + '' + endDateTime);

        return this.http.get<any[]>(api + '/api/Planning/FindPlannings', { params });
    }

    // createPlanning 
    createPlanning(value: any): Observable<any> {
        const reqdata = value;
        let restaurantId = localStorage.getItem('resturantId');
        let brokerOrganisationId = localStorage.getItem('brokerOrganisationId');
        if(restaurantId){
          return this.http.post(api + '/api/Planning/CreatePlannings?RestaurantId='+restaurantId, reqdata);
        }else if(brokerOrganisationId){
          return this.http.post(api + '/api/Planning/CreatePlannings?brokerOrganisationId='+brokerOrganisationId, reqdata);
        }
      }
      // updatePlanning 
      updatePlanning(value: any): Observable<any> {
        const reqdata = value;
        let restaurantId = localStorage.getItem('resturantId');
        let brokerOrganisationId = localStorage.getItem('brokerOrganisationId');
        if(restaurantId){
          return this.http.put(api + '/api/Planning/UpdatePlannings', reqdata);
        }else if(brokerOrganisationId){
          return this.http.put(api + '/api/Planning/UpdatePlannings', reqdata);
        }
      }
      verifyGoogleToken(token: string): Observable<any> {
        return this.http.get(`https://oauth2.googleapis.com/tokeninfo?access_token=${token}`);
      }
    
      GetGoogleAccessToken(Code:any) {
        return this.http.get(api + '/api/OAuth/GetGoogleAccessToken?Code=' + Code+'&RedirectUrl='+environment.gooleredirectUri);
      }
    
      GetMicrosoftAccessToken(Code:any) {
        return this.http.get(api + '/api/OAuth/GetMicrosoftAccessToken?Code=' + Code+'&RedirectUrl='+environment.MicrosoftredirectUri);
      }
      ExportPlanningIntoGoogleCalendar(AccessToken){
        return this.http.get<any>(api + '/api/Planning/ExportPlanningIntoGoogleCalendar?AccessToken='+AccessToken)
      }
      ExportPlanningIntoOutlookCalendar(){
        return this.http.get<any>(api + '/api/Planning/ExportPlanningIntoOutlookCalendar')
      }
      
      deletePlanning(id: string): Observable<any> {
        return this.http.delete(api + '/api/Planning/DeletePlannings?Id=' + id);
      }
      findUnAvailableUserDates(userId: string): Observable<any> {
        return this.http.get<any>(api + '/api/Planning/FindUnAvailableUserDates?UserId=' + userId);
    }
    getPlanningById(planningId: any): Observable<any[]> {
      return this.http.get<any[]>(api + '/api/Planning/FindPlanningById?Id=' + planningId);
  }
}