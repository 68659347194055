import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Unit } from '../../models/unit.model';

const api = environment.baseUrl;

@Injectable({
    providedIn: 'root'
})
export class UnitService {

    constructor(private http: HttpClient) { }

    getAllUnitsService(): Observable<Unit[]> {
        return this.http.get<Unit[]>(api + '/api/Unit/FindAllUnits');
    }
}