import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Category } from '../../models/category.model';

const api = environment.baseUrl;

@Injectable({
    providedIn: 'root'
})
export class CategoryService {
    private loadData = new Subject<any>();
    data = this.loadData.asObservable();
    private profileLoadData = new Subject<any>();
    profile = this.profileLoadData.asObservable();
    constructor(private http: HttpClient) { }

    getCategoriesService(): Observable<any> {
        const params = new HttpParams()
            .append('NumberOfRecord', '200')
            .append('PageNumber', '1')
            .append('Search', '')
            .append('RestaurantId', sessionStorage.getItem('resturantId'))

        return this.http.get(api + '/api/Category/FindCategoryBySearch', { params })
        .pipe(
            map(response => response["data"])
        );
    }


    getCategoriesListService(numberOfRecord: any, page: any, search: any): Observable<any> {
        const params = new HttpParams()
        .append('NumberOfRecord', numberOfRecord)
        .append('PageNumber', page)
        .append('Search', search)
        .append('RestaurantId', sessionStorage.getItem('resturantId'))
        return this.http.get(api + '/api/Category/FindCategoryBySearch', { params })
        .pipe(
            map(response => response['data'])
        );
    }
    getCategoriesWithparams(params): Observable<any> {
        return this.http.get(api + '/api/Category/FindCategoryBySearch', { params })
        .pipe(
            map(response => response['data'])
        );
    }

    addCategoryService(formData: FormData): Observable<any> {
        let restaurantId = Number(sessionStorage.getItem('resturantId'));
        return this.http.post(api + '/api/Category/Create?RestaurantId=' + restaurantId, formData);
    }

    updateCategoryService(formData: FormData): Observable<any> {
        let restaurantId = Number(sessionStorage.getItem('resturantId'));
        return this.http.put(api + '/api/Category/Update?RestaurantId=' + restaurantId, formData);
    }

    deleteCategoryService(id: number): Observable<any> {
        return this.http.delete(api + '/api/Category/Delete?Id=' + id);
    }

    categoryById(id: number): Observable<any> {
        return this.http.get<any>(api + '/api/Category/FindCategoryById?Id=' + id);
    }

    categoryByIdwithpaination(id,numberOfRecord: any, page: any): Observable<any> {
        const params = new HttpParams()
        .append('NumberOfRecord', numberOfRecord)
        .append('PageNumber', page)
        .append('CategoryId', id)
        .append('RestaurantId', sessionStorage.getItem('resturantId'))
        return this.http.get<[]>(api + '/api/Cart/FindCategoryItems', { params })
        .pipe(
            map(response => response["data"])
        )
        }

    findCategoryNoItem(): Observable<any> {
        return this.http.get<any>(api + '/api/Item/FindNoCategoryItems');
    }
    loadingData(isEdit: boolean, type: string) {
        let obj = {
            isEdit: isEdit,
            type: type
        }
        this.loadData.next(obj);
      }
      ProfileloadingData(isEdit: boolean, type: string) {
        let obj = {
            isEdit: isEdit,
            type: type
        }
        this.profileLoadData.next(obj);
      }
     deleteCategoryProfile(MediaItemId: number, categoryId: number): Observable<any> {
        return this.http.delete(api + '/api/Category/RemoveItemProfile?CategoryId=' + categoryId + '&MediaItemId=' + MediaItemId);
    }
    updateCategoryProfile(profile: FormData, mediaItemId: number, categoryId: number): Observable<any> {

        return this.http.put(api + '/api/Category/UpdateProfile?CategoryId=' + categoryId + '&MediaItemId=' + mediaItemId, profile);
    }
   addCategoryProfile(profile: FormData, categoryId: number): Observable<any> {
        return this.http.post(api + '/api/Category/UploadProfile?CategoryId=' + categoryId, profile);
    }

    FindOfferBucketById(id){
        return this.http.get<any>(api + '/api/OfferBucket/FindOfferBucketById?OfferBucketId='+id);
    }

    PTFindOfferBucketById(id){
        return this.http.get<any>(api + '/api/OfferBucket/PTFindOfferBucketById?OfferBucketId='+id);
    }

    GetAllImageLibraries(){
        return this.http.get<any>(api + '/api/Image/GetAllImages');
    }
}

