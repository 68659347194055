import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { RestaurantService } from 'src/app/shared/services/api/restaurant.service';
import { Level4ChartShowComponent } from '../level4-chart-show/level4-chart-show.component';
import { saveAs } from 'file-saver';
import { MessageService } from 'src/app/shared/services/message-service.service';
import { MessagesService } from 'src/app/pages/auth/Guided_Visite/messages.service';

@Component({
  selector: 'app-level3-chart-show',
  templateUrl: './level3-chart-show.component.html',
  styleUrls: ['./level3-chart-show.component.scss'],
})
export class Level3ChartShowComponent implements OnInit {
  charttype=''
  @Input() data: any;
  chartdata:any;
  chart: Chart;
  startDate:any;
  endDate:any;
  conditionA=false;
  money_spentanddelivered_items:any
  constructor(
    private readonly modalCtrl: ModalController,
    private restaurantService: RestaurantService,
    private datePipe: DatePipe,
    private messageService: MessageService,
    public guidedvisitemessage:MessagesService
    
  ) { }

  ngOnInit() {
    console.log(this.data)
    if(this.data){
      if(this.data.charttype=='ItemsReport'){
        this.chartdata=this.data?.data
        this.charttype=this.data?.charttype
        this.startDate=this.data?.data?.startDate
        this.endDate=this.data?.data?.endDate
      }else if(this.data.charttype=='CustomersReport'){
        this.startDate=this.data?.data?.startDate
        this.endDate=this.data?.data?.endDate
        this.charttype=this.data.charttype
        this.levelthrerechartforbar(this.data)
      }else if(this.data.charttype=='money_spent'||this.data.charttype=='delivered_items'){
        this.chartdata=this.data?.data?.paymentsLevel3?this.data?.data?.paymentsLevel3:this.data?.data?.deliveredItemsLevel3;
        this.money_spentanddelivered_items=this.data?.data
        this.conditionA=true  
      }
    }
  
  }


  exportitemsReport(){
    if(sessionStorage.getItem('GuidedVisite')=='true'){
      this.guidedvisitemessage.GuidedVisitemessage('Export','This button allows you to Export Report')
      }else{ 
    this.restaurantService.SoldItemReportexport(this.data.data.startDate,this.data.data.endDate, this.data.data.categoryId,this.data.data.restaurantIds).subscribe((result)=>{
      if(result){
        console.log(result)
        saveAs(result, `Itemsreport.xlsx`);
        this.messageService.presentToast('Export Repot Done')
      }
    })
  }
  }

  MoneySpentReportReport(){
    if(sessionStorage.getItem('GuidedVisite')=='true'){
      this.guidedvisitemessage.GuidedVisitemessage('Export','This button allows you to Export Report')
      }else{ 
    this.restaurantService.MoneySpentReportReport(this.money_spentanddelivered_items).subscribe((result)=>{
      if(result){
        console.log(result)
        saveAs(result, `MoneySpentReport.xlsx`);
        this.messageService.presentToast('Export Repot Done')
      }
    })
  }
  }
  DeliveryReport(){
    if(sessionStorage.getItem('GuidedVisite')=='true'){
      this.guidedvisitemessage.GuidedVisitemessage('Export','This button allows you to Export Report')
      }else{ 
    this.restaurantService.DeliveryReport(this.money_spentanddelivered_items).subscribe((result)=>{
      if(result){
        console.log(result)
        saveAs(result, `DeliveryReport.xlsx`);
        this.messageService.presentToast('Export Repot Done')
      }
    })
  }
  }
  exportmoney_spentdelivered_itemsReport(){
    if(this.data.charttype=='money_spent'){
      this.MoneySpentReportReport()
    }else{
      this.DeliveryReport()
    }
  }





  closepopup(){
    this.modalCtrl.dismiss()
  }

  levelthrerechartforbar(item) {
    // year 
    if(item.datetype==='year'||item.datetype==='Yearly'){
      this.restaurantService.FindCustomersVisits(item.data.restaurantIds,'Yearly',item.data[0].year,item.status).subscribe((result)=>{
        if(result){
          this.chartdata=result
        }
      })
    }else if(item.datetype==='month'||item.datetype==='Monthly'){
      this.restaurantService.FindCustomersVisits(item.data.restaurantIds,'Monthly',item.data[0].date,item.status).subscribe((result)=>{
        if(result){
          this.chartdata=result
        }
      })
    }else if(item.datetype==='day'||item.datetype==='dayly'){
       this.restaurantService.FindCustomersVisits(item.data.restaurantIds,'dayly',item.data[0].date+' '+item.data[0].hours,item.status).subscribe((result)=>{
        if(result){
          this.chartdata=result
        }
      })
    }

  }

  monthNameToNumber(monthName: string): number {
    const monthNames = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];
    const monthIndex = monthNames.indexOf(monthName);
    if (monthIndex !== -1) {
      return monthIndex + 1;
    } else {
      throw new Error('Invalid month name');
    }
  }




  async listOfSoldItemsorders(user:any) {
    const popover = await this.modalCtrl.create({
      component: Level4ChartShowComponent,
      componentProps: { data: { data: user, charttype: 'ItemsReport',startDate:this.startDate,endDate:this.endDate } },
    });
    await popover.present();
    const { data } = await popover.onDidDismiss();
    if (data) {
      console.log(data);
    }
  }
async GetSoldItemDetail(user:any) {
  const popover = await this.modalCtrl.create({
    component: Level4ChartShowComponent,
    componentProps: { data: { data: user, charttype: 'CustomersReport',startDate:this.startDate,endDate:this.endDate } },
  });
  await popover.present();
  const { data } = await popover.onDidDismiss();
  if (data) {
    console.log(data);
  }
}



}
