import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { IngredientService } from 'src/app/shared/services/api/ingredient.service';

@Component({
  selector: 'app-ingredient-quantity-update',
  templateUrl: './ingredient-quantity-update.component.html',
  styleUrls: ['./ingredient-quantity-update.component.scss'],
})
export class IngredientQuantityUpdateComponent implements OnInit {
  StockUpdateAddingStatus: boolean = false;
  StockUpdateLossStatus: boolean = false;
  @Input() data
  Addingvalue = ['Complete delivery', 'Partial delivery','other']
  Lossvalue = ['Cooking bad behavior', 'Expiry date','other']
  details = [{ status: '', Reason: '', Comment: '',quantity:'',expiryDate:'' }]

  constructor(
    public modalCtrl: ModalController,
    private ingredientService: IngredientService,
  ) { }

  ngOnInit() {
    console.log(this.data)
    this.details[0].quantity=this.data.quantity
    this.details[0].expiryDate=this.data.expiryDate
  }





  menuOnchange(value) {
    this.details[0].status = value
    if (value == 'Adding') {
      this.StockUpdateLossStatus = false
      this.StockUpdateAddingStatus = true
    } else {
      this.StockUpdateLossStatus = true
      this.StockUpdateAddingStatus = false
    }
  }

  searchFilter() {

  }

  StockUpdate() {
    const modal = {
      "id": this.data.id,
      "quantity": this.details[0].quantity,
      "expirationDate": this.details[0].expiryDate,
      "stockUpdateStatus": this.details[0].status,
      "reason": this.details[0].Reason,
      "comment": this.details[0].Comment,
      'RestaurantId': localStorage.getItem('resturantId')
    }
    this.ingredientService.UpdateIngredientsWithExpiry(modal).subscribe((result: any) => {
      if(result){
        this.modalCtrl.dismiss(this.details);
      }
    })
  }
}
