import { Component, OnInit, ElementRef, ViewChild, Inject, AfterViewInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { BankAccountService } from 'src/app/shared/services/api/bank-account.service';
import { environment } from 'src/environments/environment';
declare var Stripe: any;
@Component({
  selector: 'app-create-credit-card',
  templateUrl: './create-credit-card.component.html',
  styleUrls: ['./create-credit-card.component.scss'],
})
export class CreateCreditCardComponent implements OnInit, AfterViewInit {
  @ViewChild('cardNumInfo')
  public cardNumInfo: ElementRef;
  @ViewChild('cardNumError')
  public cardNumError: ElementRef;
  cardNo: any;

  @ViewChild('cardExInfo')
  public cardExInfo: ElementRef;
  @ViewChild('cardExError')
  public cardExError: ElementRef;
  cardEx: any;

  @ViewChild('cardCvcInfo')
  public cardCvcInfo: ElementRef;
  @ViewChild('cardNumError')
  public cardCvcError: ElementRef;
  cardCvc: any;


  stripe: any;
  elements: any;
  message: any


  amount: number;
  credits: number;
  planId: number
  paymentMode: number;
  clientSecret: any;

  constructor(
    private readonly fb: FormBuilder,
    private bankAccountService: BankAccountService,
    private readonly modalCtrl: ModalController
  ) { }

  ngOnInit() {

  }

  closepopup(){
    this.modalCtrl.dismiss()
  }

  ngAfterViewInit(): void {
    this.stripe = Stripe(environment.stripe_key);
    this.elements = this.stripe.elements();

    let style = {
      base: {
        color: "#32325d",
      }
    };

    this.cardNo = this.elements.create('cardNumber', { style: style });
    this.cardNo.mount(this.cardNumInfo.nativeElement);

    this.cardNo.addEventListener('change', ({ error }) => {
      const displayErrorNo = this.cardNumError.nativeElement;
      if (error) {
        displayErrorNo.textContent = error.message;
      } else {
        displayErrorNo.textContent = '';
      }
    });


    this.cardEx = this.elements.create('cardExpiry', { style: style });
    this.cardEx.mount(this.cardExInfo.nativeElement);

    this.cardEx.addEventListener('change', ({ error }) => {
      const displayErrorEx = this.cardExError.nativeElement;
      if (error) {
        displayErrorEx.textContent = error.message;
      } else {
        displayErrorEx.textContent = '';
      }
    });


    this.cardCvc = this.elements.create('cardCvc', { style: style });
    this.cardCvc.mount(this.cardCvcInfo.nativeElement);

    this.cardCvc.addEventListener('change', ({ error }) => {
      const displayErrorCvc = this.cardCvcError.nativeElement;
      if (error) {
        displayErrorCvc.textContent = error.message;
      } else {
        displayErrorCvc.textContent = '';
      }
    });
  }

  async onSubmit() {
    const { token, error } = await this.stripe.createToken(this.cardNo).then();
    if (error) {
      console.log(error);
      const displayError = this.cardNumError.nativeElement;
      displayError.textContent = error.message;
    } else {
      console.log(token);
      console.log('sucess onsubmit');
      let token_str, card_str;
      for (let [key, value] of Object.entries(token)) {
        if ('id' === key) {
          token_str = value;
        } else if ('card' === key) {
          for (let [key, value1] of Object.entries(value)) {
            if ('id' === key) {
              card_str = value1;
            }
          }
        }
      } 


      let brokerOrganisationId = sessionStorage.getItem('brokerOrganisationId');
      let restaurantId = Number(sessionStorage.getItem('resturantId'));
         if(restaurantId){
          this.bankAccountService.CreateCardDetails({"token": token_str,"cardId": card_str,"isActive": true,}).subscribe(data => {
            console.log(data.response[0].message)
            if (data.response[0].message == "Credit card created successfully") {
              this.message = "Credit card created successfully"
              this.dismiss();
            } else {
              this.message = "Something went worng do again"
            }
          })
         }else if(brokerOrganisationId){
          this.bankAccountService.CreateBrokerCreditCard({"token": token_str,"cardId": card_str,"isActive": true,}).subscribe(data => {
            console.log(data.response[0].message)
            if (data.response[0].message == "Credit card created successfully") {
              this.message = "Credit card created successfully"
              this.modalCtrl.dismiss(true)
            } else {
              this.message = "Something went worng do again"
            }
          })
         }

    }
  }
  dismiss() {
    this.modalCtrl.dismiss(
      {
        deleted: true
      }
    );
  }
}
