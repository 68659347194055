import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Ingredient } from '../../models/ingredient.model';

const api: string = environment.baseUrl;

@Injectable({
  providedIn: 'root'
})
export class IngredientService {
  constructor(private http: HttpClient) { }

  getIngredientsService(numberOfRecord, page, search): Observable<Ingredient[]> {
    const params = new HttpParams()
      .append('NumberOfRecord', numberOfRecord)
      .append('PageNumber', page)
      .append('Search', search)
      .append('RestaurantId', localStorage.getItem('resturantId'));

    return this.http.get<Ingredient[]>(api + '/api/Ingredients/FindIngredientsBySearch', { params })
      .pipe(
        map(response => response["data"])
      );
  }


  getIngredients(params): Observable<Ingredient[]> {
    return this.http.get<Ingredient[]>(api + '/api/Ingredients/FindIngredientsBySearch', { params })
      .pipe(
        map(response => response["data"])
      );
  }

  getAllIngredientsService(numberOfRecord, page, search): Observable<any> {
    const params = new HttpParams()
      .append('NumberOfRecord', numberOfRecord)
      .append('PageNumber', page)
      .append('Search', search)
      .append('RestaurantId', localStorage.getItem('resturantId'));

    return this.http.get<Ingredient[]>(api + '/api/Ingredients/FindIngredientsBySearch', { params })
  }

  addIngredientService(formData: FormData): Observable<any> {
    let restaurantId = localStorage.getItem('resturantId');
    return this.http.post(api + '/api/Ingredients/Create?RestaurantId=' + restaurantId, formData);
  }

  updateIngredientService(ingredient): Observable<any> {
    console.log(ingredient)
    let restaurantId = localStorage.getItem('resturantId');
    return this.http.put(api + '/api/Ingredients/Update?RestaurantId=' + restaurantId, ingredient);
  }

  deleteIngredientService(id: number): Observable<any> {
    return this.http.delete(api + '/api/Ingredients/DeletePermanantly?Id=' + id);
  }
  deleteIngredientProfile(mediaItemId: number, IngredientId: number): Observable<any> {
    return this.http.delete(api + '/api/Ingredients/RemoveProfile?IngredientId=' + IngredientId + '&MediaItemId=' + mediaItemId);
  }
  updateIngredientProfile(profile: FormData, mediaItemId: number, ingredientId: number): Observable<any> {

    return this.http.put(api + '/api/Ingredients/UpdateProfile?IngrediantId=' + ingredientId + '&MediaItemId=' + mediaItemId, profile);
  }
  getIngredientById(id: number): Observable<any> {
    return this.http.get<any>(api + '/api/Ingredients/FindIngredientById?Id=' + id);
  }
  addIngredientProfile(profile: FormData, ingredientId: number): Observable<any> {
    return this.http.post(api + '/api/Ingredients/UploadProfile?IngrediantId=' + ingredientId, profile);
  }
  UpdateIngredientsWithExpiry(model): Observable<any> {
    return this.http.put(api + '/api/Ingredients/UpdateIngredientsWithExpiry', model);
  }
  // updateItemProfile(profile: FormData, mediaItemId: number, itemId: number): Observable<any> {

  //     return this.http.put(api + '/api/Item/UpdateItemProfile?ItemId=' + itemId + '&MediaItemId=' + mediaItemId, profile);
  // }


  GetIngredientHIstory(ingrediantId: number): Observable<any> {

    return this.http.get<any>(api + '/api/Ingredients/GetIngredientHIstory?ingrediantId=' + ingrediantId);
  }

  FindAllIngredientHistory(ingrediantId: number,StartDate,EndDate,IsOrder,IsStock): Observable<any> {
    let restaurantId = localStorage.getItem('resturantId');
    return this.http.get<any>(api + '/api/Ingredients/FindAllIngredientHistory?IngredientId=' + ingrediantId+'&RestaurantId='+  restaurantId+  '&StartDate='+StartDate+'&EndDate='+EndDate+'&NumberOfRecord='+0+'&PageNumber='+1+'&IsStock='+IsStock+'&IsOrder='+IsOrder);
  }

  GetIngredientexpiry(ingrediantId: number): Observable<any> {
    return this.http.get<any>(api + '/api/Ingredients/GetExpirationById?ingredientId=' + ingrediantId);
  }


  FindIngredientTrackings(ingrediantId: number) {
    return this.http.get<any>(api + '/api/Ingredients/FindIngredientTrackings?ingrediantId=' + ingrediantId);

  }

  downloadTemplate(name) {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', }) }
    return this.http.post(api + '/api/Import/DownloadImportTemplate?Name=' + name, httpOptions, { responseType: 'blob' });
  }


  uploadFileForTemplate(formdata) {
    return this.http.post(api + '/api/Import/UploadImportFile', formdata);
  }

  uploadFileForTemplateinBroker(formdata) {
    return this.http.post(api + '/api/Import/UploadImportFileBrokerMode', formdata);
  }


  getTemplatecol(tablename) {
    return this.http.get<any>(api + '/api/Import/GetImportTemplateColumns?name=' + tablename);

  }
  getCustomFilecol(file) {
    return this.http.post(api + '/api/Import/GetImportColumnNames', file);
  }

  CreateOfferBucket(formdata) {
    return this.http.post(api + '/api/OfferBucket/CreateOfferBucket', formdata);
  }

  SaveMappings(bodydata) {
    let RestaurantId = localStorage.getItem('resturantId');
    let brokerOrganisationId = localStorage.getItem('brokerOrganisationId');
    if(RestaurantId){
      return this.http.post(api + '/api/Mapping/SaveMappings', bodydata);
    }else if(brokerOrganisationId){
    return this.http.post(api + '/api/Mapping/SaveMappingsForBroker', bodydata);
    }

  }

  UpdateMappings(bodydata) {
    let RestaurantId = localStorage.getItem('resturantId');
    return this.http.put(api + '/api/Mapping/UpdateMappingConfiguration?RestaurantId=' + RestaurantId, bodydata);
  }
  RemoveMappings(Id, IsFile) {
    let RestaurantId = localStorage.getItem('resturantId');
    return this.http.delete(api + '/api/Mapping/RemoveConfiguration?IsFile=' + IsFile + '&Id=' + Id);
  }


  FindFileMappingConfigurationsDropdown(tablename) {
    let RestaurantId = localStorage.getItem('resturantId');
    let brokerOrganisationId = localStorage.getItem('brokerOrganisationId');
    if(RestaurantId){
    return this.http.get<any>(api + '/api/Mapping/FindFileMappingConfigurationsDropdown?TableName=' + tablename);
    }else if(brokerOrganisationId){
    return this.http.get<any>(api + '/api/Mapping/FindFileMappingConfigurationsDropdownForBroker?TableName=' + tablename+'&brokerOrganisationId='+brokerOrganisationId);

    }
    
  }

  FindConfigurationDetails(MapId, IsFile) {
    return this.http.get<any>(api + '/api/Mapping/FindConfigurationDetails?MapId=' + MapId + '&IsFile=' + IsFile);

  }

  importmapping(formdata, name) {
    let RestaurantId = localStorage.getItem('resturantId');
    let brokerOrganisationId = localStorage.getItem('brokerOrganisationId');
    if(RestaurantId){
      return this.http.post(api + '/api/Mapping/MapImport?Name=' + name + '&RestaurantId=' + RestaurantId, formdata);
    }else if(brokerOrganisationId){
      return this.http.post(api + '/api/Mapping/BrokerModeMapImport?Name=' + name + '&brokerOrganisationId=' + brokerOrganisationId, formdata);
    }
  }



  Exportdatawithformat(tableName, format, ids) {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', }) }
    return this.http.post(api + '/api/Import/ExportTables?tableName=' + tableName + '&format=' + format + '&ids=' + ids, httpOptions, { responseType: 'blob' });
  }

  FindActiveCustomersForDropdown() {
    const params = new HttpParams()
      .append('RestaurantId', localStorage.getItem('resturantId'));
    return this.http.get<Ingredient[]>(api + '/api/Customer/FindActiveCustomersForDropdown', { params })
  }


  GetFilterMenu(MapId, IsFile) {
    return this.http.get<any>(api + '/api/Mapping/FindConfigurationDetails?MapId=' + MapId + '&IsFile=' + IsFile);

  }
  GetAllVerticals() {
    return this.http.get<any>(api + '/api/Verticals/GetAllVerticals');

  }
  GetAllSubVerticals() {
    return this.http.get<any>(api + '/api/Verticals/GetAllSubVerticals');

  }
  GetFilterImagesByIds(verticalIds,subVerticalIds,imageNames) {
    return this.http.get<any>(api + '/api/Image/GetFilterImagesByIds?verticalIds='+verticalIds+'&subVerticalIds='+subVerticalIds+'&imageNames='+imageNames);

  }
  FindUsedIngredientHistoryBasedOrder(id) {
    let RestaurantId = localStorage.getItem('resturantId');
    return this.http.get<any>(api + '/api/Ingredients/FindUsedIngredientHistory?id='+id+'&RestaurantId='+RestaurantId);

  }
  ShareMappingConfiguration(MappingId, UserIds) {
    return this.http.post(api + '/api/Mapping/ShareMappingConfiguration?MappingId=' + MappingId ,UserIds);
  }


  createstrategy(data){
    return this.http.post(api + '/api/Strategy/CreateStrategy',data);
  }


  GetStrategyById(id){
    return this.http.get(api + '/api/Strategy/FindStrategyById?strategyId='+id);
  }
  UpdateStrategy(data){
    return this.http.put(api + '/api/Strategy/UpdateStrategy',data);
  }


}