import { AfterViewInit, Component, OnInit } from '@angular/core';
import { AlertController, MenuController, ModalController, Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { AppService } from './app.service';
import { User } from './shared/models/user.model';
import { ProfileComponent } from './pages/profile/profile.component';
import { NAV_ADMIN, cashier, cook, customer, provider_admin, provider_user, provider_user1, provider_user2, provider_user3, stock_Mgmt, waiter,broker } from './shared/navigation';
import { AuthService } from './shared/services/api/auth.service';
import { Subscription } from 'rxjs';
import { RestaurantService } from './shared/services/api/restaurant.service';
import { PermissionCheckService } from './shared/services/permission-check.service';
import { GlobalTranslationService } from './shared/services/global-translation.service';
import { ProfilePickFormatService } from './shared/services/profile-pick-format.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  user: any;
  public selectedIndex = 0;
  public navigations: any[];
  localStoragedValue: any;
  detailRecivedSubscription: Subscription;
  tablemanagementpermission: Boolean = true
  Planningpermission: Boolean = true
  drawerData:any;
  userproviderpermistion: boolean = false
  userreportpermistion: boolean = false
  readonly languages = [];
  language:any;
  screentypeMobile:any=false;
  size: any;
  width: number; 
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private translate: TranslateService,
    private router: Router,
    private menuCtrl: MenuController,
    private appService: AppService,
    private modalController: ModalController,
    private authService: AuthService,
    private _rsestaurantService: RestaurantService,
    public PermissionCheckService: PermissionCheckService,
    private globaltranslate: GlobalTranslationService,
    private profilepickformat: ProfilePickFormatService,
    private alertCtrl: AlertController, 
  ) { 
    this.width = window.innerWidth;
    if (!this.size && this.width < 600) {
      this.screentypeMobile = true;
      if(!sessionStorage.getItem('screentypeMobile')){
        sessionStorage.setItem('screentypeMobile','true') 
      }
    } else if (!this.size && this.width > 600) {
      this.screentypeMobile = false;
      if(!sessionStorage.getItem('screentypeMobile')){
      sessionStorage.setItem('screentypeMobile','false') 
      }
    }
    if(sessionStorage.getItem('screentypeMobile')){
      this.screentypeMobile=sessionStorage.getItem('screentypeMobile')
    }
    this.languages=this.globaltranslate.getAlllanguages()
    this.language=this.globaltranslate.setselectedlanguage()
    if(sessionStorage.getItem('lang')){
     this.translate.use(sessionStorage.getItem('lang'));
   }else{
     this.translate.use('English');
   }
    this.CheckAllavailablepermission()
    this.initializeApp();
    this.detailRecivedSubscription = this.authService.data.subscribe(res => {
      console.log(res);
      this.localStoragedValue = res;
      sessionStorage.setItem('isEdit', this.localStoragedValue)
      this.localStoragedValue = sessionStorage.getItem('isEdit');
      const roleName = sessionStorage.getItem('roleName');
      console.log(this.localStoragedValue)
      if (this.localStoragedValue === false || this.localStoragedValue === 'false') {
        if (roleName === 'Super Manager' || roleName === 'Manager') {
          this.navigations = NAV_ADMIN;
        } else if (roleName === 'Cashier') {
          this.navigations = cashier;
        } else if (roleName === 'Cook') {
          this.navigations = cook;
        } else if (roleName === 'Waiter') {
          this.navigations = waiter;
        } else if (roleName === 'Customers') {
          this.navigations = customer;
        } else if (roleName === 'Stock Management') {
          this.navigations = stock_Mgmt
        }
      } else if (this.localStoragedValue === true || this.localStoragedValue === 'true') {
        if (roleName === 'Super Admin') {
          this.navigations = provider_admin;
        } else if (roleName === 'Stock Management') {
          this.navigations = stock_Mgmt;
        } else if (roleName === 'User') {
          const rights = JSON.parse(sessionStorage.getItem('permission'))
          console.log(rights)
          rights.forEach(element => {
            if (element.rights == 'Provider') {
              this.userproviderpermistion = true
            } else if (element.rights == 'Report') {
              this.userreportpermistion = true
            }
          });
          setTimeout(() => {
            if (this.userproviderpermistion && this.userreportpermistion) {
              this.navigations = provider_user3;
            } else if (!this.userproviderpermistion && !this.userreportpermistion) {
              this.navigations = provider_user;
            } else if (this.userproviderpermistion) {
              this.navigations = provider_user2;
            } else if (this.userreportpermistion) {
              this.navigations = provider_user1;
            }
          }, 10);
        }
      }
      this.user = {};
      if (sessionStorage.getItem('user')) {
        let user = sessionStorage.getItem('user');
        this.user = JSON.parse(user);
        console.log(this.user);
      }
    });
  }

  getuserFLnames(value1,value2) {
    return this.profilepickformat.getuserFLnames(value1,value2);
  }

  getColor(index) {
    return this.profilepickformat.getColor(index);
  } 

  onSelectChange(event: any) {
    console.log('Selected value:', event.detail.value);
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      this.translate.addLangs(['en', 'fr']);
      this.translate.setDefaultLang('en');
      this.authService.isAuthenticated$.subscribe(data => {
        this.menuCtrl.enable(true);
      });
    });
  }
  findTableManagementAvailable() {
    this._rsestaurantService.findTableManagementAvailable().subscribe((result: any) => {
      if (result) {
        this.tablemanagementpermission = result
      }
      console.log(result)
    })
  }

  findPlanningAvailable() {
    this._rsestaurantService.findPlanningAvailable().subscribe((result: any) => {
      if (result) {
        this.Planningpermission = result
      }
    })
  }

  CheckAllavailablepermission() {
    this.findTableManagementAvailable()
    this.findPlanningAvailable()
  }




  ngOnInit() {
    this.localStoragedValue = sessionStorage.getItem('isEdit');
    console.log(this.localStoragedValue);
    const roleName = sessionStorage.getItem('roleName');
    console.log(roleName)
    if (this.localStoragedValue === false || this.localStoragedValue === 'false') {
      if (roleName === 'Super Manager' || roleName === 'Manager') {
        this.navigations = NAV_ADMIN;
      } else if (roleName === 'Cashier') {
        this.navigations = cashier;
      } else if (roleName === 'Cook') {
        this.navigations = cook;
      } else if (roleName === 'Waiter') {
        this.navigations = waiter;
      } else if (roleName === 'Customers') {
        this.navigations = customer;
      } else if (roleName === 'Stock Management') {
        this.navigations = stock_Mgmt
      }else if ( roleName=='Broker_Super Admin'||roleName=='Broker_Admin'||roleName=='Broker_User') {
        this.navigations = broker;
      }
    } else if (this.localStoragedValue === true || this.localStoragedValue === 'true') {
      if (roleName === 'Super Admin') {
        this.navigations = provider_admin;
      } else if (roleName === 'Stock Management') {
        this.navigations = stock_Mgmt;
      } else if (roleName === 'User') {
        const rights = JSON.parse(sessionStorage.getItem('permission'))
        console.log(rights)
        rights.forEach(element => {
          if (element.rights == 'Provider') {
            this.userproviderpermistion = true
          } else if (element.rights == 'Report') {
            this.userreportpermistion = true
          }
        });
        setTimeout(() => {
          if (this.userproviderpermistion && this.userreportpermistion) {
            this.navigations = provider_user3;
          } else if (!this.userproviderpermistion && !this.userreportpermistion) {
            this.navigations = provider_user;
          } else if (this.userreportpermistion) {
            this.navigations = provider_user2;
          } else if (this.userproviderpermistion) {
            this.navigations = provider_user1;
          }
        }, 10);
      }else if (roleName=='Broker_Super Admin'||roleName=='Broker_Admin'||roleName=='Broker_User') {
        this.navigations = broker;
      }
    }


    this.user = {};
    if (sessionStorage.getItem('user')) {
      const user = sessionStorage.getItem('user');
      this.user = JSON.parse(user);
      console.log(this.user);
    }

    this.authService.isAuthenticated$.subscribe(data => {
      this.menuCtrl.enable(true);
    });
  }




  async showProfile() {
    const modal = await this.modalController.create({
      component: ProfileComponent,
      componentProps: {
        user: this.user
      }
    });
    return await modal.present();
  }




  logout() {
    sessionStorage.removeItem('resturantId');
    sessionStorage.removeItem('resturantName');
    sessionStorage.removeItem('resturantProfile');
    sessionStorage.removeItem('password');
    sessionStorage.removeItem('userId');
    sessionStorage.removeItem('providerId');
    sessionStorage.removeItem('isEdit');
    sessionStorage.removeItem('email');
    sessionStorage.removeItem('providerProfilePicture');
    sessionStorage.removeItem('providerName');
    sessionStorage.removeItem('roleName');
    sessionStorage.removeItem('user');
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('permission');
    sessionStorage.removeItem('screentypeMobile');
    sessionStorage.clear();
    this.menuCtrl.enable(false);
    this.router.navigate(['auth']).then(() => {
      window.location.reload();
    });
  }


  async logoutConfirmation() {
    let alert = await this.alertCtrl.create({
      header: 'Confirmation',
      message:'Are you sure you want to logout ?',
      buttons: [
        {
          text: 'No',
          role: 'No',
          handler: () => {
            console.log('Cancel clicked');
          },
        },
        {
          text: 'Yes',
          handler: () => {
            this.logout()
          },
        },
      ],
    });
    await alert.present();
  }




  selectLanguage(value: string) {
    this.language = this.languages.find((lang) => lang.value === value);
    sessionStorage.setItem('lang', this.language.value);
    window.location.reload();
  }
}
function then(arg0: () => void) {
  throw new Error('Function not implemented.');
}

