import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { User } from 'src/app/shared/models/user.model';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  form: FormGroup;
  @Input() user: User;

  constructor(
    public modalController: ModalController,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit() {

    this.form = this.formBuilder.group({
      userName: [this.user.userName],
      firstName: [this.user.firstName],
      lastName: [this.user.lastName],
      email: [this.user.email],
      restaurantName: [this.user.restaurantName],
      address: [this.user.address],
      city: [this.user.city],
      zipCode: [this.user.zipCode],
      country: [this.user.country],
      roleName: [this.user.roleName]
    });
  }

}
