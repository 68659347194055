import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class MessagesService {

  constructor(
    private alertCtrl: AlertController,
  ) { 

  }



  async GuidedVisitemessage(header,message) {
    let alert = await this.alertCtrl.create({
      header: header,
      message: message,
      buttons: [
        {
          text: 'OK',
          cssClass: 'custom-button-ok',
          handler: () => {
            console.log('OK clicked');
          },
        },
        // {
        //   text: 'Pay',
        //   cssClass: 'custom-button-pay',
        //   handler: () => {
        //     console.log('Pay for full usage clicked');
        //   },
        // },
      ],
    });
    await alert.present();
  }
}
