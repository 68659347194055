import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
const api = environment.baseUrl;
@Injectable({
  providedIn: 'root'
})
export class PayService {

  constructor(private http: HttpClient) { }
  payAmount(data: any): Observable<any> {
    console.log(data);
    let restaurantId = Number(sessionStorage.getItem('resturantId'));
    return this.http.post(api + "/api/Pay/AddPayAmount?RestaurantId=" + restaurantId, data)
  }

  generateBill(data: any): Observable<any> {
    console.log(data);
    return this.http.post(api + "/api/Pay/GenerateBill", data)
  }
  SendBillToEmail(customerId,formData){
    const headers = new HttpHeaders().append('Content-Disposition', 'multipart/form-data');
    return this.http.post<any[]>(api + '/api/Customer/SendBillToEmail?customerId='+customerId,formData,{ headers: headers });
}

  generateinvoiceForprovider(id): Observable<any> {
    return this.http.get(api + "/api/Invoice/FindProviderInvoice?id="+id)
  }

  generateinvoice(id): Observable<any> {
    let restaurantId = Number(sessionStorage.getItem('resturantId'));
    return this.http.get(api + "/api/Invoice/FindInvoice?id="+id+'&RestaurantId='+restaurantId)
  }

  FindBrokerInvoice(id): Observable<any> {
    return this.http.get(api + "/api/Invoice/FindBrokerInvoice?id="+id)
  }

  generateinvoiceForProviderHistory(data): Observable<any> {
    return this.http.put(api + "/api/Deal/UpdateDealStatus",data)
  }

  FindInvoiceByDealId(id,IsQuote): Observable<any> {
    return this.http.get(api + "/api/Deal/FindInvoiceByDealId?dealId="+id+'&IsQuote='+IsQuote)
  }

  FindQRPayment(OrderId,CustomerName,Amount): Observable<any> {
    return this.http.get(api + "/api/Order/QRPayment?OrderId="+OrderId+'&CustomerName='+CustomerName+'&Amount='+Amount)
  }
  
}
