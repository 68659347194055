export const NAV_ADMIN = [
  {
    title: 'Order Management',
    url: 'orders',
    icon: 'list-alt',
    fontsize:'15px'
                                                                                    
  }, 
  {
    title: 'Food Management',
    url: 'food-mgmt',
    icon: 'flask',
    fontsize:'15px'
                                                                                    
  },
  {
    title: 'Point Of Sale',
    url: 'menu',
    icon: 'utensils',
    fontsize:'15px'
                                                                                    
  },
  {
    title: 'Planning',
    url: 'planning',
    icon: 'calendar-check',
    fontsize:'15px'
                                                                                    
  },
  {
    title: 'Table Management',
    url: 'table-mgmt',
    icon: 'chair',
    fontsize:'15px'
                                                                                    
  }, 
  {
    title: 'Market place',
    url: 'supplier-mgmt',
    icon: 'shield-alt',
    fontsize:'15px'
                                                                                    
  },
  {
    title: 'Resource Management',
    url: 'res-mgmt',
    icon: 'address-book',
    fontsize:'15px'
                                                                                    
  },
  {
    title: 'Restaurant Management',
    url: 'restaurant-mgmt',
    icon: 'store',
    fontsize:'13px'
                                                                                    
  },
  {
    title: 'Reports',
    url: 'reports',
    icon: 'chart-line',
    fontsize:'15px'
                                                                                    
  },
];
export const provider_admin = [
  {
    title: 'Provider Management',
    url: 'provider-restaurant-mgmt',
    icon: 'shield-alt',
    fontsize:'15px'
                                                                                    
  },
  {
    title: 'Resource Management',
    url: 'provider-res-mgmt',
    icon: 'address-book',
    fontsize:'15px'
                                                                                    
  },
  {
    title: 'Market Place',
    url: 'provider-supplier-mgmt',
    icon: 'store',
    fontsize:'15px'
                                                                                    
  },
  {
    title: 'Reports',
    url: 'reports',
    icon: 'chart-line',
    fontsize:'15px'
                                                                                    
  },
];
export const provider_user= [
  {
    title: 'Resource Management',
    url: 'provider-res-mgmt',
    icon: 'address-book',
    fontsize:'15px'
                                                                                    
  },
  {
    title: 'Market Place',
    url: 'provider-supplier-mgmt',
    icon: 'store',
    fontsize:'15px'
                                                                                    
  },
];
export const provider_user1= [
  {
    title: 'Provider Management',
    url: 'provider-restaurant-mgmt',
    icon: 'shield-alt',
    fontsize:'15px'
                                                                                    
  },
  {
    title: 'Resource Management',
    url: 'provider-res-mgmt',
    icon: 'address-book',
    fontsize:'15px'
                                                                                    
  },
  {
    title: 'Market Place',
    url: 'provider-supplier-mgmt',
    icon: 'store',
    fontsize:'15px'
                                                                                    
  },
];
export const provider_user2= [
  {
    title: 'Resource Management',
    url: 'provider-res-mgmt',
    icon: 'address-book',
    fontsize:'15px'
                                                                                    
  },
  {
    title: 'Market Place',
    url: 'provider-supplier-mgmt',
    icon: 'store',
    fontsize:'15px'
                                                                                    
  },
  {
    title: 'Reports',
    url: 'reports',
    icon: 'chart-line',
    fontsize:'15px'
                                                                                    
  },
];
export const provider_user3= [
  {
    title: 'Provider Management',
    url: 'provider-restaurant-mgmt',
    icon: 'shield-alt',
    fontsize:'15px'
                                                                                    
  },
  {
    title: 'Resource Management',
    url: 'provider-res-mgmt',
    icon: 'address-book',
    fontsize:'15px'
                                                                                    
  },
  {
    title: 'Market Place',
    url: 'provider-supplier-mgmt',
    icon: 'store',
    fontsize:'15px'
                                                                                    
  },
  {
    title: 'Reports',
    url: 'reports',
    icon: 'chart-line',
    fontsize:'15px'
                                                                                    
  },
];
export const stock_Mgmt = [
  {
    title: 'Planning',
    url: 'planning',
    icon: 'calendar-check',
    fontsize:'15px'
                                                                                    
  },
  {
    title: 'Food Management',
    url: 'food-mgmt',
    icon: 'flask',
    fontsize:'15px'
                                                                                    
  },
  {
    title: 'Market place',
    url: 'supplier-mgmt',
    icon: 'shield-alt',
    fontsize:'15px'
                                                                                    
  },
];
export const cashier = [
  {
    title: 'Point Of Sale',
    url: 'menu',
    icon: 'utensils',
    fontsize:'15px'
                                                                                    
  },
  {
    title: 'Planning',
    url: 'planning',
    icon: 'calendar-check',
    fontsize:'15px'
                                                                                    
  },
  {
    title: 'Table Management',
    url: 'table-mgmt',
    icon: 'chair',
    fontsize:'15px'
                                                                                    
  },
  {
    title: 'Order Management',
    url: 'orders',
    icon: 'list-alt',
    fontsize:'15px'
                                                                                    
  },
  {
    title: 'Resource Management',
    url: 'res-mgmt',
    icon: 'address-book',
    fontsize:'15px'
                                                                                    
  },
];
export const cook = [
  {
    title: 'Order Management',
    url: 'orders',
    icon: 'list-alt',
    fontsize:'15px'
                                                                                    
  },
  {
    title: 'Food Management',
    url: 'food-mgmt',
    icon: 'flask',
    fontsize:'15px'
                                                                                    
  },
  {
    title: 'Point Of Sale',
    url: 'menu',
    icon: 'utensils',
    fontsize:'15px'
                                                                                    
  },
  {
    title: 'Planning',
    url: 'planning',
    icon: 'calendar-check',
    fontsize:'15px'
                                                                                    
  },
  {
    title: 'Resource Management',
    url: 'res-mgmt',
    icon: 'address-book',
    fontsize:'15px'
                                                                                    
  },
  {
    title: 'Table Management',
    url: 'table-mgmt',
    icon: 'chair',
    fontsize:'15px'
                                                                                    
  },
  {
    title: 'Market place',
    url: 'supplier-mgmt',
    icon: 'shield-alt',
    fontsize:'15px'
                                                                                    
  },
];
export const waiter = [
  {
    title: 'Order Management',
    url: 'orders',
    icon: 'list-alt',
    fontsize:'15px'
                                                                                    
  },
  {
    title: 'Food Management',
    url: 'food-mgmt',
    icon: 'flask',
    fontsize:'15px'
                                                                                    
  },
  {
    title: 'Point Of Sale',
    url: 'menu',
    icon: 'utensils',
    fontsize:'15px'
                                                                                    
  },
  {
    title: 'Planning',
    url: 'planning',
    icon: 'calendar-check',
    fontsize:'15px'
                                                                                    
  },
  {
    title: 'Table Management',
    url: 'table-mgmt',
    icon: 'chair',
    fontsize:'15px'
                                                                                    
  },
];
export const customer = [
  {
    title: 'Orders Management',
    url: 'orders',
    icon: 'list-alt',
    fontsize:'15px'
                                                                                    
  },
  {
    title: 'Point Of Sale',
    url: 'menu',
    icon: 'utensils',
    fontsize:'15px'
                                                                                    
  },
  {
    title: 'Table Management',
    url: 'table-mgmt',
    icon: 'chair',
    fontsize:'15px'
                                                                                    
  },
  {
    title: 'Resource Management',
    url: 'res-mgmt',
    icon: 'address-book',
    fontsize:'15px'
                                                                                    
  },
];
export const broker = [
  {
    title: 'Organization Management',
    url: 'org-manage',
    icon: 'briefcase',
    fontsize:'15px'
                                                                                    
  },
  {
    title: 'Broker Management ',
    url: 'broker-manage',
    icon: 'address-book',
    fontsize:'15px'
                                                                                    
  },
  {
    title: 'Planning',
    url: 'planning',
    icon: 'calendar-check',
    fontsize:'15px'
                                                                                    
  },
  {
    title: 'Resource Management',
    url: 'Resource-Management',
    icon: 'address-book' ,
    fontsize:'15px'
                                                                                    
  },
  {
    title: 'Reports',
    url: 'reports',
    icon: 'chart-line' ,
  fontsize:'15px'
   },
];

