import { Component, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { User } from 'src/app/shared/models/user.model';
import { CategoryService } from 'src/app/shared/services/api/category.service';
import { ConstantDataService } from 'src/app/shared/services/api/constant-data.service';
import { ManagementService } from 'src/app/shared/services/api/management.service';
import { UserService } from 'src/app/shared/services/api/user.service';
import { MessageService } from 'src/app/shared/services/message-service.service';
import { MessagesService } from '../../auth/Guided_Visite/messages.service';
import { AddNewTagComponent } from 'src/app/components/add-new-tag/add-new-tag.component';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { GoogleValidationAddressService } from 'src/app/shared/services/google-validation-address.service';

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss'],
})
export class UserEditComponent implements OnInit {
  @Input() user: User;
  imagePath: any;
  imageFile: File;
  form: FormGroup;
  isSubmitted = false;
  roles: any[];
  userList: any;
  copyUser: any;
        location = 'BARNES LE MARAIS125, Rue Vieille-du-Temple,Paris 3ème,France';
        @ViewChild('placesRef') placesRef!: GooglePlaceDirective;
        options = {
          types: ['address'],
          componentRestrictions: {},
        };
  constructor(
    private constantDataService: ConstantDataService,
    private userService: UserService,
    private formBuilder: FormBuilder,
    public modalCtrl: ModalController,
    private messageService: MessageService,
    private categoryService: CategoryService,
    private managementService: ManagementService,
    public guidedvisitemessage:MessagesService,
    private injector: Injector,
  ) {

  }

  ngOnInit() {
    this.getRole();
    console.log(this.userList)
    this.userList = this.user;
    this.copyUser =  {...this.user};
    this.form = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      gender: ['', Validators.required],
      email: ['', [Validators.required]],
      address: ['', Validators.required],
      zipCode: ['', Validators.required],
      city: ['', Validators.required],
      country: ['', Validators.required],
      phoneNumber: ['', Validators.required],
      roleName: [, Validators.required],
      TagViewModels: this.formBuilder.array([]), 
      Latitude:[0],
      Longitude:[0]
    });
    let genderId = this.userList.genderValue === 'Male' ? 0 : 1;
    this.form.patchValue({
      firstName: this.userList.firstName,
      lastName: this.userList.lastName,
      gender: genderId,
      email: this.userList.email,
      address: this.userList.address,
      zipCode: this.userList.zipCode,
      city: this.userList.city,
      country: this.userList.country,
      phoneNumber: this.userList.phoneNumber,
      roleName: this.userList.roleName
    });
    
    if(this.userList?.tagViewModels.length!=0){
      this.userList?.tagViewModels.forEach((element,index) => {
      this.addtageBody()
        this.gettageBody().at(index).get('tagType')?.setValue(element?.tagType)
        this.gettageBody().at(index).get('fieldType')?.setValue(element?.fieldType)
        this.gettageBody().at(index).get('dropdownValues')?.setValue(element?.getDropdownValues)
        this.gettageBody().at(index).get('fieldValue')?.setValue(element?.fieldvalue.toString())
        this.gettageBody().at(index).get('fieldName')?.setValue(element?.fieldName)
        this.gettageBody().at(index).get('tagTableId')?.setValue(element?.tagTableId)
      });
    }

    console.log(this.form.value)
    this.imagePath = this.userList.profilePicture;
  }


    // tages 
 
    gettageBody() {
      return this.form.get('TagViewModels') as FormArray;
    }
    addtageBody() {
      this.gettageBody().push(this.createtageBody());
    }
    fieldname(i: number) {
      return this.gettageBody().at(i).get('fieldName')?.value;
    }
    fieldMandatory(i: number) {
      return this.gettageBody().at(i).get('Mandatory')?.value;
    }
    dropdownvaluetotal(i: number) {
      return this.gettageBody().at(i).get('dropdownvaluetotal')?.value.split(',');
    }
    removetag(i){
      this.gettageBody().removeAt(i)
    }
    fieldType(i: number) {
      return this.gettageBody().at(i).get('fieldType')?.value;
    }
    createtageBody(): FormGroup {
      return this.formBuilder.group({
        Mandatory:[false],
        tagTableId: 0,
        fieldName: '',
        fieldType: '', 
        fieldValue: '',
        dropdownValues: '',
        dropdownvaluetotal: '',
        tagType:''
      });
    }
    
    async addNewTag() {
      const modal = await this.modalCtrl.create({
        component: AddNewTagComponent 
      }); 
      await modal.present();
      const { data } = await modal.onDidDismiss();
      if (data) {
        console.log(data)
        this.addtageBody()
        var lenghtfortagbody=this.form.value.TagViewModels.length
        console.log(lenghtfortagbody)
        this.gettageBody().at(lenghtfortagbody-1).get('tagType')?.setValue(data.tagType)
        this.gettageBody().at(lenghtfortagbody-1).get('fieldType')?.setValue(data.fieldType)
        this.gettageBody().at(lenghtfortagbody-1).get('dropdownValues')?.setValue(data.Adddropdownvalue)
        this.gettageBody().at(lenghtfortagbody-1).get('fieldValue')?.setValue(data.fieldvalue.toString())
        this.gettageBody().at(lenghtfortagbody-1).get('fieldName')?.setValue(data.fieldName)
        this.gettageBody().at(lenghtfortagbody-1).get('Mandatory')?.setValue(data.Mandatory)
        console.log(this.form.value.TagViewModels)
      }
    }



  getRole() {
    this.constantDataService.findRolesService().subscribe(data => this.roles = data);
  }
  get errorControl() {
    return this.form.controls;
  }
  removeProfileImage() {
    if(sessionStorage.getItem('GuidedVisite')=='true'){
      this.guidedvisitemessage.GuidedVisitemessage('Remove','This button allows you to Remove Profile')
      }else{ 
    this.imagePath = null;
    this.imageFile = null;
    this.userService.deleteUserProfile(this.userList.mediaItemId ? this.userList.mediaItemId : 0).subscribe((res: any) => {
      this.messageService.presentToast(res.message);
      this.userList.mediaItemId = null;
      this.categoryService.loadingData(true, 'user');
      if (res.message) {
        this.submitForm();
      }
    })
  }
  }
  uploadImage() {
    const formData = new FormData();
    formData.append('file', this.imageFile);
    this.userService.addProfile(formData, this.userList.id,this.userList?.mediaItemId).subscribe((res: any) => {
      this.messageService.presentToast('Profile picture added');
      this.userList.mediaItemId = Number(res.message);
      this.categoryService.loadingData(true, 'user');
      if (res.message) {
        this.submitForm();
      }
    });
  }

  editProfileImage() {
    const formData = new FormData();
    formData.append('file', this.imageFile);
    this.userService.updateUserProfile(formData, this.userList.mediaItemId ? this.userList.mediaItemId : 0).subscribe((res: any) => {
      this.messageService.presentToast(res.message);
      if (res.message) {
        this.submitForm();
      }
      this.categoryService.loadingData(true, 'user');
    });
  }
  setImage(ev: any) {
    const reader = new FileReader();
    this.imageFile = ev.target.files[0];
    reader.readAsDataURL(ev.target.files[0]);
    reader.onload = (event) => {
      this.imagePath = reader.result;
    };
  }

  roleChanged(ev: any) {
    let value = ev.detail.value;
    this.roles.forEach((ele) => {
      if (ele.name === value) {
        this.userList.roleName = ele.name;
      }
    })

  }
  submitForm() {
    if (this.form.valid) {
      const formData = new FormData();
      formData.append('firstName', this.form.value.firstName);
      formData.append('lastName', this.form.value.lastName);
      formData.append('gender', this.form.value.gender);
      formData.append('email', this.form.value.email);
      formData.append('address', this.form.value.address);
      formData.append('zipCode', this.form.value.zipCode);
      formData.append('Latitude', this.form.value.Latitude);
      formData.append('Longitude', this.form.value.Longitude);
      formData.append('city', this.form.value.city);
      formData.append('country', this.form.value.country);
      formData.append('phoneNumber', this.form.value.phoneNumber);
      formData.append('roleName', this.form.value.roleName);
      formData.append('profile', this.imageFile);
      this.form.value.TagViewModels.forEach((element:any, index:any) => {
        if (element?.fieldValue != null) {
          formData.append(
            'TagViewModels[' + index + '].fieldName',
            element.fieldName
          );
          formData.append(
            'TagViewModels[' + index + '].fieldType',
            element.fieldType
          );
          formData.append('TagViewModels[' + index + '].tagTableId', '');
          formData.append(
            'TagViewModels[' + index + '].dropdownValues',
            element.dropdownValues
          );
          formData.append(
            'TagViewModels[' + index + '].fieldValue',
            element?.fieldValue == null ? ' ' : element?.fieldValue.toString()
          );
        }
        if (element?.fieldType == 'Dropdown') {
          formData.append(
            'TagViewModels[' + index + '].fieldName',
            element.fieldName
          );
          formData.append(
            'TagViewModels[' + index + '].fieldType',
            element.fieldType
          );
          formData.append('TagViewModels[' + index + '].tagTableId', '');
          element.dropdownValues.forEach(element => {
            if(element.dropdownvalue!=null&&element?.dropdownvalue!=''){
              formData.append('TagViewModels[' + index + '].dropdownValues',element.dropdownValues.toString());
            }
            
          });
          formData.append(
            'TagViewModels[' + index + '].fieldValue',
            element?.fieldValue == null ? ' ' : element?.fieldValue.toString()
          );
        }
      });
      this.userService.updateUserService(this.user.id,formData).subscribe(data => {
        console.log(data)
        this.messageService.presentToast('User updated');
        this.modalCtrl.dismiss(true);
      });
    }

  }
  onSubmit() {
    if(sessionStorage.getItem('GuidedVisite')=='true'){
      this.guidedvisitemessage.GuidedVisitemessage('Update','This button allows you to Update User')
      }else{ 
    this.isSubmitted = true;
    this.userList.profilePicture = this.imagePath;
    this.userList.mediaItemId = this.userList.mediaItemId;
    if (this.userList.mediaItemId && this.userList.profilePicture && this.imageFile) {
      this.editProfileImage();
    } else if (this.userList.mediaItemId && !this.userList.profilePicture) {
      this.removeProfileImage();
    } else if (this.userList.mediaItemId && this.userList.profilePicture && this.imageFile) {
      this.uploadImage();
    } else if(this.copyUser.roleName === this.form.value.roleName && this.copyUser.email === this.form.value.email) {
      this.submitForm();
    }

    if (this.copyUser.roleName !== this.form.value.roleName) {
      this.updateRole();
    }
    if (this.copyUser.email !== this.form.value.email) {
      this.updateMail();
    }
  }
  }


  
  updateRole() {
    this.managementService.updateRoles(this.userList.id, this.form.value.roleName).subscribe((res) => {
      this.messageService.presentToast('User role updated successfully.');
      if (res.message) {
        this.submitForm();
      }
      this.categoryService.loadingData(true, 'user');
    })
  }

  updateMail() {
    const pwd = sessionStorage.getItem('password');
    let user = {
      email: this.form.value.email,
      password: pwd
    }
    this.userService.updateUserMail(user).subscribe((res) => {
      this.messageService.presentToast(res.message);
      if (res.message) {
        this.submitForm(); 
      }
      this.categoryService.loadingData(true, 'user');
    })
  }
    handleAddressChange(place: object) {
          const data = this.injector.get(GoogleValidationAddressService).getFullAddress(place);
          console.log(data)
          this.form.get('Latitude')?.setValue(data.latitude);
          this.form.get('Longitude')?.setValue(data.longitude);
      
          if (
            data.Address != undefined &&
            data.Address != null &&
            data.Address != 'undefined' &&
            data.Address != 'null'
          ) {
            this.form.get('address')?.setValue(data.Address);
          } else {
            this.form.get('address')?.setValue('');
          }
          if (
            data.pincode != undefined &&
            data.pincode != null &&
            data.pincode != 'undefined' &&
            data.pincode != 'null'
          ) {
            this.form.get('zipCode')?.setValue(data.pincode);
          } else {
            this.form.get('zipCode')?.setValue('');
          }
          if (
            data.city != undefined &&
            data.city != null &&
            data.city != 'undefined' &&
            data.city != 'null'
          ) {
            this.form.get('city')?.setValue(data.city);
          } else {
            this.form.get('city')?.setValue('');
          }
          if (
            data.country != undefined &&
            data.country != null &&
            data.country != 'undefined' &&
            data.country != 'null'
          ) {
            this.form.get('country')?.setValue(data.country);
          } else {
            this.form.get('country')?.setValue('');
          }
        }
  
}
