import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptor implements HttpInterceptor {

  constructor() { }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const token = sessionStorage.getItem('token');
    if(token!=null){
      req = req.clone({
        setHeaders: {
          Authorization: 'Bearer ' + token 
        }
      });
    return next.handle(req);
    }else{
      return next.handle(req);
    }
  }
}