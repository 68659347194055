import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { MessageService } from '../services/message-service.service';

const api: string = environment.baseUrl;

@Injectable({
    providedIn: 'root'
})
export class CartService {

    private cartItemCount = new BehaviorSubject(0);

    constructor(
        private http: HttpClient,
         private messageService: MessageService
         ) { }

    getCartItemCount() {
        return this.cartItemCount;
    }

    addProduct(product) : Observable<any>{     
        let restaurantId = localStorage.getItem('resturantId');
        this.cartItemCount.next(this.cartItemCount.value);
        return this.http.post(api + '/api/Cart/AddProductsInCart?RestaurantId=' + restaurantId, product);
    }

    getCartItem(restaurantId) : Observable<any>{
      return this.http.get<any[]>(api + '/api/Cart/FindCartProviderProducts?RestaurantId=' + restaurantId);
    }

    getItemList(page, limit) : Observable<any>{
        let restaurantId = localStorage.getItem('resturantId');
      return this.http.get<any[]>(api + '/api/Cart/FindCartProviderProducts?Search=' + '' +'&PageNumber=' + page + '&NumberOfRecord='+ limit + '&RestaurantId=' + restaurantId);
    }
    RemoveItemList(CartId) : Observable<any>{
      return this.http.delete<any[]>(api + '/api/Cart/RemoveProductFromCart?CartId=' + CartId );
    }

    UpdateDealStatus(data) : Observable<any>{
      return this.http.put<any[]>(api + '/api/Deal/UpdateDealStatus' ,data );
    }

    AcceptRejectDeal(data) : Observable<any>{
      return this.http.put<any[]>(api + '/api/Deal/AcceptRejectDeal' ,data );
    }

    FindProviderDealProducts() : Observable<any>{
      let providerId = localStorage.getItem('providerId');
    return this.http.get<any[]>(api + '/api/Cart/FindProviderDealProducts?providerId='  + providerId);
  }
}
