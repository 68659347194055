

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { F } from '@angular/cdk/keycodes';
import { PlanningService } from 'src/app/shared/services/api/planning.service';

@Component({
  selector: 'app-microsoft-auth-redirect',
  templateUrl: './microsoft-auth-redirect.component.html',
  styleUrls: ['./microsoft-auth-redirect.component.scss']
})
export class MicrosoftAuthRedirectComponent implements OnInit {
  user: any; // Initialize with null
  accessToken: any
  microsoftaccessToken: any
  resposncesstatus: any=false
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private planningService: PlanningService,
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      console.log(params)
      if (params?.['code']) {
        this.planningService.SynchronizeMicrosoftAccount(params?.['code']).subscribe((result) => {
          if (result) {
            this.resposncesstatus=true
            setTimeout(() => {
              if (result) {
                this.resposncesstatus = true
                console.log(result)
                this.router.navigate(['/planning'])

              }
             }, 100);
          }
        }, error => {
        if(error.status==200){
          this.resposncesstatus=true
          setTimeout(() => {
            this.router.navigate(['/planning'])
           }, 100);
        }else{
          this.resposncesstatus = false
        }
        })
      }
    });
  }

}

