import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Calory } from '../../models/calory.model';

const api = environment.baseUrl;

@Injectable({
    providedIn: 'root'
})
export class CaloryService {

    constructor(private http: HttpClient) { }

    getAllCaloriesService(): Observable<Calory[]> {
        return this.http.get<Calory[]>(api + '/api/Calory/FindAllCalory');
    }
}