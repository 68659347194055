import { Component, Input, OnInit } from '@angular/core';
import { ModalController, PopoverController } from '@ionic/angular';
import { TableService } from '../../services/api/table.service';
import { MessagesService } from 'src/app/pages/auth/Guided_Visite/messages.service';

@Component({
  selector: 'app-delete',
  templateUrl: './delete.component.html',
  styleUrls: ['./delete.component.scss'],
})
export class DeleteComponent implements OnInit {
  @Input() name: string;
  @Input() data: any;

  constructor(
    public popoverCtrl: PopoverController,
    private tableService: TableService,
    private readonly modalCtrl: ModalController,
    public guidedvisitemessage:MessagesService

  ) {}

  ngOnInit() {
    console.log(this.data);
  }

  Deletetable() {
    if(sessionStorage.getItem('GuidedVisite')=='true'){
      this.guidedvisitemessage.GuidedVisitemessage('Delete','This button allows you to Delete')
      }else{
    this.modalCtrl.dismiss(true)
    this.popoverCtrl.dismiss(true)
      }
  }
}
