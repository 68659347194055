import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';

@Injectable({
  providedIn: 'root'
})
export class PermissionCheckService {
  tableManagementPermission$ = new Subject<Boolean>();
  tableManagementPermission = this.tableManagementPermission$.asObservable();
  planningPermmision$ = new Subject<Boolean>();
  planningPermmision = this.planningPermmision$.asObservable();
  constructor() { }
}
